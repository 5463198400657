// src/components/DepositDetectionBot.js
import { Dialog, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import bnb from '../../Image/bbb.png';
import eth from '../../Image/eth.png';
import sol from '../../Image/sol.png';
import usdt from '../../Image/usdt.png';
import detect from '../../Image/detection.png';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../Config/axios';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../CommonComponent/Toast";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBalance } from '../../redux/balanceSlice';

const containerVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 120,
      delay: 0.5,
    },
  },
};

const content = [
  "Hello,",
  "I am a detection bot.",
  "I detected your crypto deposit",
  "on the blockchain!"
];

export default function DepositDetectionBot({ Open, setOpen }) {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [counter, setCounter] = useState(10);
  const { ethAddress, solAddress } = userData;
  const [selectedToken, setSelectedToken] = useState('BNB');
  

  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [startDetection, setStartDetection] = useState(false);
  const [amt,setAmt]= useState();
  const [selectedAssets, setSelectedAssets] = useState({
    token: 'SOL',
    network: 'SOL20',
  });

  const balanceStatus = useSelector((state) => state.balance.status);

  const dispatch = useDispatch();
  // const { assets, status, error } = useSelector((state) => state.balance);
  // console.log(assets, "asset");

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  // useEffect(() => {
  //   if (balanceStatus === 'idle') {
  //     dispatch(fetchBalance());
  //   }
  // }, [balanceStatus, dispatch]);

  useEffect(() => {
    let isTyping = true;

    const typingAnimation = async () => {
      for (let i = 0; i < content.length && isTyping; i++) {
        setCurrentContentIndex(i);
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    };

    if (Open) {
      setCurrentContentIndex(0);
      typingAnimation();
    }

    return () => {
      isTyping = false;
    };
  }, [Open]);

  const handleDepositAmount = async ({amount,asset}) => {
    try {
      const bodyData = {
        assets: asset,
        chain: selectedAssets.network,
        amount: Number(amount),
      };

      const response = await axiosInstance.post('/transaction/deposit', bodyData);

      const { data: responseData } = response;

      if (!responseData.status) {
        toast.error(<Toast message={responseData.message} status="error" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      } else {
        toast.success(<Toast message={responseData.message} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      }
      setStartDetection(false);
      setOpen(false);

    } catch (error) {
      setStartDetection(false);
      toast.error(<Toast message={error.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    }
  };

  const Handlecheck=()=>{
       if(selectedAssets.network === '' || selectedAssets.token === ''){
        return <p className='bg-red-500'>please select network</p>
       }
       else{
        setCounter(9);
       }
  }

  const handleStartDetection = async () => {
    setStartDetection(true);

    try {
      const bodyData = {
        chain: selectedAssets.network,
        asset: selectedAssets.token,
      };

      if (selectedAssets.token === 'ETH' || selectedAssets.token === 'BNB') {
        bodyData['depositAddress'] = ethAddress;
      } else if (selectedAssets.token === 'SOL') {
        bodyData['depositAddress'] = solAddress;
      } else if (selectedAssets.token === 'USDT') {
        if (selectedAssets.network === 'ERC20' || selectedAssets.network === 'BEP20') {
          bodyData['depositAddress'] = ethAddress;
        } else {
          bodyData['depositAddress'] = solAddress;
        }
      }
      


      bodyData['depositAddress'] = 'EWCHSVvBef7zZzjXjiBtazvFb1v1Gudxg8tGP3GzJxVM'; // TESTING

      const response = await axiosInstance.post('/user/detect', bodyData);
      const { data: responseData } = response;
      if (!responseData.status) {
        return { status: false, message: responseData.message, data: null }
      } else {
        return { status: true, message: responseData.message, data: responseData.data }
      }

    } catch (error) {
      setStartDetection(false);
      toast.error(<Toast message={error.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
      return { status: false, message: error.message, data: null }

    }
  };

  useEffect(() => {
    if (counter === 10) return;
    const intervalId = setInterval(async () => {
      const { status, message, data } = await handleStartDetection();
      if (status) {
        clearInterval(intervalId);
        toast.success(<Toast message={message} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
        setStartDetection(false);
        setCounter(10);
         
      } 
      else if(counter === 0) {
        clearInterval(intervalId);
        toast.error(<Toast message={"try again! deposit not detected"} status="warn" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide   
        });
        setStartDetection(false);
      }
      else {
        setCounter(counter-1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter]);

  return (
    <Dialog
      open={Open}
      onClose={() => setOpen(false)}
      PaperProps={{
        className: '!min-w-[30%] !h-[55%] p-5 flex gap-3 flex-col !rounded-2xl !bg-black',
        style: {
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <motion.div
        className="flex flex-col items-center justify-center h-full"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="flex justify-between items-center w-full">
          <h2 className="text-white text-2xl font-bold">Deposit Detection Bot</h2>
          <CloseIcon className="text-white cursor-pointer" onClick={() => setOpen(false)} />
        </div>
        <motion.img
          src={detect}
          alt="Detection"
          className="w-24 h-24 mt-4"
          variants={itemVariants}
        />
        <div className="text-white text-lg mt-4">
          {content.map((line, index) => (
            <motion.p
              key={index}
              variants={itemVariants}
              initial="hidden"
              animate={index <= currentContentIndex ? "visible" : "hidden"}
            >
              {line}
            </motion.p>
          ))}
        </div>
        <div className="mt-8 w-full">
          <div className="flex gap-3 mb-4">
            {/* <img src={bnb} alt="BNB" className="w-8 h-8 cursor-pointer" onClick={() => setSelectedAssets({ token: 'BNB', network: 'BEP20' })} /> */}
            {/* <img src={eth} alt="ETH" className="w-8 h-8 cursor-pointer" onClick={() => setSelectedAssets({ token: 'ETH', network: 'ERC20' })} /> */}
            <img src={sol} alt="SOL" className="w-8 h-8 cursor-pointer" onClick={() => setSelectedAssets({ token: 'SOL', network: 'SOL20' })} />
            {/* <img src={usdt} alt="USDT" className="w-8 h-8 cursor-pointer" onClick={() => setSelectedAssets({ token: 'USDT', network: '' })} /> */}
          </div>
          {selectedAssets.token === 'USDT' && (
            <div className="flex gap-3 mb-4">
              <Button variant="contained" className="!bg-gray-600" onClick={() => setSelectedAssets({ ...selectedAssets, network: 'ERC20' })}>ERC20</Button>
              <Button variant="contained" className="!bg-gray-600" onClick={() => setSelectedAssets({ ...selectedAssets, network: 'BEP20' })}>BEP20</Button>
              <Button variant="contained" className="!bg-gray-600" onClick={() => setSelectedAssets({ ...selectedAssets, network: 'SOLANA' })}>SOLANA</Button>
            </div>
          )}
            {startDetection ?  <p className='text-white text-center'>waiting for {counter} sec</p>:""}
          <Button
            variant="contained"
            color="primary"
            onClick={() => Handlecheck() }
            disabled={startDetection}
            className="w-full mt-4"
            >
            {startDetection ? 'Detecting...' : 'Start Detection'}
          </Button>
            {/* <Handlecheck /> */}
        </div>
      </motion.div>
    </Dialog>
  );
}
