import React from 'react'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Cards from '../Common/Cards';
const ShowSkipCardModal = ({ card }) => {
    return (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-70">
            <div className="animated_scale text-white rounded-lg max-w-md relative flex items-center justify-center">
                <Cards card={card} className='h-24 w-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
                <DoNotDisturbIcon fontSize='100' className=' text-white text-9xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
            </div>
        </div>
    )
}

export default ShowSkipCardModal
