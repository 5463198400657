import React, { useState } from 'react'
import Card from '../Common/Cards'
import axiosInstance from '../../../../../Config/axios'
import hotToast from 'react-hot-toast'
import { useParams } from 'react-router-dom'



const PlayerCards = ({ cards, tableCard }) => {


    const { gameAddress } = useParams()
    const [isPlayed, setIsPlayed] = useState(false)

    
    const isCardPlayable = (card) => {
        if (isPlayed) return false;
        if (card.value === 'wild') return true;
        if (card.value === 'wildDrawFour') {
            // check if any card is playable
            const cardValues = cards.map(card => card.value);
            const cardColors = cards.map(card => card.color);


            if (cardValues.includes(tableCard.value) || cardColors.includes(tableCard.color)) return false;

            return true;
        }
        if (card.color === tableCard.color || card.value === tableCard.value) return true;

        return false;
    }

    const handlePlayCard = async (card, index) => {

        if (!isCardPlayable(card)) return;

        if (isPlayed) return;

        try {
            setIsPlayed(true);
            const bodyData = { gameAddress, card }
            const response = await axiosInstance.post('/api/game/uno-card-game/play-card', bodyData);

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsPlayed(false);
                hotToast.error(responseData.message, { duration: 3000 })
            } else {
                setIsPlayed(false);
            }
        } catch (error) {
            setIsPlayed(false);
            hotToast.error(error.message, { duration: 3000 })
        }

    }


    return (
        <div className='flex w-full h-auto items-center justify-center gap-1'>
            {cards.map((card, index) => (
                <span id={`current_player_card_${index}`} role='button' onClick={() => handlePlayCard(card, index)} className={`active:scale-110 fade-in-up ${!isCardPlayable(card) && 'cursor-not-allowed'}`}><Card  card={card} key={index}/></span>
            ))}
        </div>
    )
}

export default PlayerCards
