import React, { useState, useEffect } from "react";
import axiosInstance from "../../../Config/axios";
import { useNavigate } from "react-router-dom";
import JoinGameModal from "../../../CommonComponent/JoinGameModal";
import { isEmpty } from "lodash";
import { motion } from "framer-motion";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const navigate = useNavigate();
  const [gameList, setGameList] = useState([]);
  const [joinModalInfo, setJoinModalInfo] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(null); // State to manage which dropdown is open

  const fetchGameList = () => {
    axiosInstance
      .post("/api/game/list")
      .then((res) => {
        setGameList(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchGameList();
  }, []);


  const handleJoinGameSubmit = (values) => {
    navigate(`/play/${values.slug}/${values.gameAddress}`);
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id); // Toggle dropdown
  };

  const handleMouseEnter = (id) => {
    setDropdownOpen(id);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  const handleGameClick = (game) => {
    navigate(`/game-view/${game.slug}`);
  };

  return (
    <>
      <div className="p-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {gameList.map((game) => (
          <motion.div
            key={game.id}
            className="w-full max-w-sm bg-gray-100 text-white bg-dark border-gray-200 rounded-md shadow dark:bg-gray-800 dark:border-gray-700 relative m-3" // Added margin
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onMouseLeave={handleMouseLeave}
          >
            {/* Game Card */}
            <div
              onClick={() => handleGameClick(game)}
              role="button"
              className="game_card flex flex-col h-60 shadow-lg p-0 relative overflow-hidden rounded-md hover:scale-105 transition-transform duration-300"
              onMouseEnter={() => handleMouseEnter(game.id)} // Handle mouse enter
              onMouseLeave={handleMouseLeave} // Handle mouse leave
            >
              <img className="w-full h-full object-cover" src={game.image} alt={game.title} />

              {/* Play Icon */}
              <div
                className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300`}
              >
                <PlayCircleFilledWhiteIcon style={{ fontSize: 50, color: "#FFD700" }} />
              </div>

              {/* Game Info */}
              <div className="absolute bottom-0 right-0 rounded-b-md w-full bg-gradient-to-t from-black to-transparent p-2">
                <div className="game_card_content text-white">
                  <h5 className="mb-1 font-extrabold text-white">{game.title}</h5>
                  <p className="text-xs">
                    <strong className="font-bold">Minimum Bet:</strong> {game.minimum_bet} Sol
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {!isEmpty(joinModalInfo) && (
        <JoinGameModal
          data={joinModalInfo}
          show={setJoinModalInfo}
          handleSubmit={handleJoinGameSubmit}
          handleClose={() => setJoinModalInfo(null)}
        />
      )}
    </>
  );
}

export default App;
