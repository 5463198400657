import React, { useEffect, useState } from 'react'
import Loading from './Common/Loading'
import JoinUsers from './JoinUsers'
import PlayGame from './Play'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../../../Config/axios'
import { Slide, toast } from 'react-toastify'
import ToastContent from '../../../../CommonComponent/Toast'
import { useSelector } from 'react-redux'
import socketIOClient from 'socket.io-client';
import hotToast from 'react-hot-toast';
import GameResult from './Result/index'
import Chat from '../../../ChatingComponent/chatingComponent'

const ENDPOINT = process.env.REACT_APP_API_URL;

const FiveCardDrawPoker = () => {
  const { gameAddress } = useParams()
  const userData = useSelector(state => state.auth.userData)

  const [gameInfo, setGameInfo] = useState({})
  const [socket, setSocket] = useState(null)

  const fetchGameInfo = async () => {
    try {
      const bodyData = { gameAddress }
      const response = await axiosInstance.post('/api/game/5-card-poker/info', bodyData)

      const { data: responseData } = response

      if (!responseData.status) {
        toast.error(<ToastContent title="Error" message={responseData.message} />, {
          transition: Slide, autoClose: 3000, hideProgressBar: true, theme: 'dark', position: 'bottom-center'
        })
      } else {
        setGameInfo(responseData.data)
        if (socket) {
          socket.emit('joinedGame', { userId: userData?.id, roomId: gameAddress })
        }
      }
    } catch (error) {
      toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, theme: 'dark', transition: Slide })
    }
  }

  useEffect(() => {
    fetchGameInfo()
  }, [gameAddress, socket])

  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('opponentJoined', (data) => {
      fetchGameInfo()
    });

    newSocket.on('opponentLeft', (data) => {
      const userId = data.userId
      if (userId === userData?.id) {
        hotToast.success('You left the game!', { duration: 5000, position: 'bottom-center' })
      } else {
        hotToast.success('Opponent Left!', { duration: 5000, position: 'bottom-center' })
        fetchGameInfo()
      }
    })

    newSocket.on('gameStarted', (data) => {
      hotToast.success('Game Started!', { duration: 2000, position: 'bottom-center' })
      fetchGameInfo()
    });

    newSocket.on('gameOver', (data) => {
      hotToast.success('Game Over! ', { duration: 2000, position: 'bottom-center' })
      setTimeout(() => {
        fetchGameInfo()
      }, 500);
    })


    return () => {
      newSocket.off('opponentJoined')
      newSocket.off('opponentLeft')
      newSocket.off('gameStarted')
      newSocket.off('gameOver')
    }

  }, [gameAddress])

  const gamePlayers = gameInfo?.players || []
  const myProfile = gamePlayers.find(player => player.userId === userData?.id)

  // if(true) return   <Chat userId={userData?.id}  roomId={gameAddress}  />;

  if (!gameInfo) return <Loading />
  if (gameInfo.status === "pending") return <JoinUsers  gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />
  if (gameInfo.status === "started") return <PlayGame gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />
  if (gameInfo.status === "completed" && !myProfile ) return <GameResult gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />
  if (gameInfo.status === "completed" && myProfile ) return <JoinUsers gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />
 
}
export default FiveCardDrawPoker
