import React, { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl ,Connection} from '@solana/web3.js';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');



const Wallet = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Testnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [

            new PhantomWalletAdapter(),
            new UnsafeBurnerWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect >
                <WalletModalProvider>
                    {/* <WalletMultiButton /> */}
                    {/* <WalletDisconnectButton /> */}
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default Wallet;
// import React, { useMemo, ReactNode } from 'react';
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { PhantomWalletAdapter, UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
// import {
//     WalletModalProvider,
//     // WalletDisconnectButton,
//     // WalletMultiButton
// } from '@solana/wallet-adapter-react-ui';
// import { clusterApiUrl } from '@solana/web3.js';

// // Default styles that can be overridden by your app
// require('@solana/wallet-adapter-react-ui/styles.css');

// interface WalletProps {
//     children: ReactNode;
// }

// const Wallet: React.FC<WalletProps> = ({ children }) => {
//     // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
//     const network: WalletAdapterNetwork = WalletAdapterNetwork.Testnet;

//     // You can also provide a custom RPC endpoint.
//     const endpoint = useMemo(() => clusterApiUrl(network), [network]);

//     const wallets = useMemo(
//         () => [
//             new PhantomWalletAdapter(),
//             new UnsafeBurnerWalletAdapter(),
//         ],
//         [network]
//     );

//     return (
//         <ConnectionProvider endpoint={endpoint}>
//             <WalletProvider wallets={wallets} autoConnect>
//                 <WalletModalProvider>
//                     {/* <WalletMultiButton /> */}
//                     {/* <WalletDisconnectButton /> */}
//                     {children}
//                 </WalletModalProvider>
//             </WalletProvider>
//         </ConnectionProvider>
//     );
// };

// export default Wallet;
