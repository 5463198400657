import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { StakingApp } from '../Authentication/Context/StakingApp';
import { useSelector } from 'react-redux';
import { BiWallet, BiMoney, BiArrowToBottom, BiTransfer, BiCog, BiUserPlus, BiLogOut } from 'react-icons/bi';

const DropdownComponent = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { user } = useContext(StakingApp);
  const { data } = useSelector((state) => state.userData);
  const userData = JSON.parse(localStorage.getItem('userData'));

  const trimEthereumAddress = (address) => {
    let firstPart = address.slice(0, 6);
    let lastPart = address.slice(-4);
    return `${firstPart}...${lastPart}`;
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    Cookies.remove('token');
    localStorage.clear(userData);
    window.location.reload();
  };

  return (
    <div className="relative">
      <div
        className="flex items-center justify-center w-12 h-12 rounded-full bg-[#202020] cursor-pointer border-2 border-[#FFD700] transition duration-200 ease-in-out transform hover:scale-110"
        onClick={toggleDropdown}
      >
        <img
          id="avatarButton"
          className="w-10 h-10 rounded-full"
          src={data?.profileUrl || '/default-avatar.png'} // Use a default avatar if none is provided
          alt="User avatar"
        />
      </div>

      {isDropdownOpen && (
        <div
          id="userDropdown"
          className="absolute right-0 mt-2 w-64 bg-[#1A1A1A] rounded-xl shadow-lg overflow-hidden z-20"
        >
          <div className="p-4 text-center border-b border-[#303030]">
            <img
              src={data?.profileUrl || '/default-avatar.png'}
              alt="User profile"
              className="mx-auto mb-2 w-16 h-16 rounded-full border-2 border-[#FFD700]"
            />
            <div className="text-[#FFD700] text-lg font-semibold">{data?.userName}</div>
            <div className="text-gray-400 text-sm">
              Address: {trimEthereumAddress(user?.userAddress)}
            </div>
          </div>
          <ul className="py-1">
            <li>
              <Link
                to="/wallet"
                className="flex items-center px-4 py-3 text-[#FFD700] hover:bg-[#FFD700] hover:text-black transition duration-300"
                onClick={toggleDropdown}
              >
                <BiWallet className="mr-3 text-lg" /> Wallet
              </Link>
            </li>
            <li>
              <Link
                to="/deposit"
                className="flex items-center px-4 py-3 text-[#FFD700] hover:bg-[#FFD700] hover:text-black transition duration-300"
                onClick={toggleDropdown}
              >
                <BiArrowToBottom className="mr-3 text-lg" /> Deposit
              </Link>
            </li>
            <li>
              <Link
                to="/withdrawal"
                className="flex items-center px-4 py-3 text-[#FFD700] hover:bg-[#FFD700] hover:text-black transition duration-300"
                onClick={toggleDropdown}
              >
                <BiMoney className="mr-3 text-lg" /> Withdrawal
              </Link>
            </li>
            <li>
              <Link
                to="/transaction"
                className="flex items-center px-4 py-3 text-[#FFD700] hover:bg-[#FFD700] hover:text-black transition duration-300"
                onClick={toggleDropdown}
              >
                <BiTransfer className="mr-3 text-lg" /> Transaction History
              </Link>
            </li>
            <li>
              <Link
                to="/setting"
                className="flex items-center px-4 py-3 text-[#FFD700] hover:bg-[#FFD700] hover:text-black transition duration-300"
                onClick={toggleDropdown}
              >
                <BiCog className="mr-3 text-lg" /> Settings
              </Link>
            </li>
          </ul>
          <div className="border-t border-[#303030]">
            <span
              onClick={() => {
                navigate('/referralearning');
                toggleDropdown();
              }}
              className="flex items-center px-4 py-3 cursor-pointer text-[#FFD700] hover:bg-[#FFD700] hover:text-black transition duration-300"
            >
              <BiUserPlus className="mr-3 text-lg" /> Referral and Earnings
            </span>
          </div>
          <div className="border-t border-[#303030]">
            <span
              onClick={() => {
                handleClose();
                toggleDropdown();
              }}
              className="flex items-center px-4 py-3 cursor-pointer text-[#FFD700] hover:bg-red-500 hover:text-white transition duration-300"
            >
              <BiLogOut className="mr-3 text-lg" /> Sign Out
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
