import React, { useEffect, useState } from 'react'
import { formatAddress } from '../../../../../../Config/function'
import Card from '../../ common/index'
import HiddenCard from '../../ common/HiddenCard'
import { useSelector } from 'react-redux'
import FoldImage from '../../../../../../assets/img/fold.png'
import LeftImage from '../../../../../../assets/img/left.png'

const Player = ({ gameStatus, timer, player, showCards, currentPlayer, playerCards, foldedPlayers, leftPlayers, userBalances }) => {
  const userData = useSelector(state => state.auth.userData)

  const [cards, setCards] = useState(playerCards || [])

  const myProfile = (player?.userId == userData?.id)
  const isPlayerMove = player?.userId == currentPlayer


  useEffect(() => {
    setCards(playerCards)
  }, [playerCards])

  const newPlayerCard = cards.find((item) => item.userId === player.userId)?.cards || []

  const isFolded = foldedPlayers?.includes(player.userId)
  const isLeft = leftPlayers?.includes(player.userId)


  const playerBalance = userBalances?.find((item) => item?.userId === player.userId)?.amount || 0

  const ProgressTimer = ({ value }) => {
    const [timeLeft, setTimeLeft] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        if (timeLeft === 0) return;
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);

    }, [timeLeft, value])


    let progressColor = 'bg-green-500 text-black';
    if (timeLeft < 10) {
      progressColor = 'bg-red-500 text-black';
    } else if (timeLeft < 20) {
      progressColor = 'bg-yellow-500 text-black';
    }

    let textColor = 'text-black';

    if (timeLeft == 0) return null;

    return (
      <span className={`progress-timer__bar ${progressColor} h-full w-full flex items-center justify-center rounded-full ${textColor}`}>
        {timeLeft}
      </span>
    );
  }

  const totalHiddenCards = newPlayerCard.filter((card) => !card.isOpen).length


  return (
    <div className={`hi-lo-player flex items-center flex-col	`}>


      <div className='relative cards flex flex-row items-center justify-between gap-2 mb-2 '>


        {!isFolded && !isLeft && (showCards) && !myProfile &&
          newPlayerCard.map((card, index) => {
            return (
              card.isOpen
                ? <Card className={'fade-in-up h-20 w-14 rounded-md shadow-md shadow-gray-400'} key={index} card={card} />
                : ''
            )
          })}
        {!isFolded && !isLeft && showCards && !myProfile && <HiddenCard cards={totalHiddenCards} className={'fade-in-up h-20 w-14'} />}
        {isFolded && !isLeft && <img src={FoldImage} alt='fold' className='w-24 h-12' />}
        {isLeft && !isFolded && <img src={LeftImage} alt='left' className='w-24 h-12' />}




      </div>
      <div className={`${isPlayerMove && "border-2 border-white py-2 flex flex-row items-center justify-between px-3 bg-green-400 rounded-full relative"}`}>
        <div className={`flex flex-row items-center justify-center p-1 bg-black font-extrabold px-1 text-center border-2 rounded-md `}>
          <img src={player.profileUrl} alt='avatar' className='w-10 h-10 rounded-full mr-3 border-2 border-white' />
          <div className='flex flex-col justify-between items-start'>
            <div className='name text-white mb-1 text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>
            {player.userId === userData?.id ? <div className='balance text-white text-xs'>{Number(playerBalance).toFixed(2)} Sol</div> : <div className='balance text-white  text-xs'> *** Sol</div>}
          </div>
        </div>
        <div className={`absolute ${player.userId === userData?.id ? '-top-4' : '-bottom-4'} rounded-full left-1/2 transform -translate-x-1/2 text-xs h-8 w-8 font-extrabold`}>
          {timer && (currentPlayer === player.userId) && (gameStatus != 'starting') && <ProgressTimer value={timer} />}
        </div>
      </div>
    </div>
  )
}

export default Player
