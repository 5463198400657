import React, { useEffect, useState } from 'react'
import BetButtons from './BetButtons';
import { useSelector } from 'react-redux';
import ShowDownAction from './ShowDownAction';
const SevenCardPokerActionButtons = ({maxBet, pot, handleSelectedCardSubmit, gameBetStatus, currentPlayer }) => {


    const userData = useSelector(state => state.auth.userData)


    return (
        <div className=' flex flex-col items-center justify-center h-full w-full'>
            {(currentPlayer == userData?.id) && (gameBetStatus.round !== 'showdown') && <BetButtons gameBetStatus={gameBetStatus} maxBet={maxBet} pot={pot} />}
            {(currentPlayer == userData?.id) && (gameBetStatus.round == 'showdown') && <ShowDownAction handleSelectedCardSubmit={handleSelectedCardSubmit}/>}
        </div>
    )
}

export default SevenCardPokerActionButtons
