import React, { useEffect, useState } from 'react'
import hotToast from 'react-hot-toast'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../Config/axios';

const UnoCallButton = () => {

    const [isSelected, setIsSelected] = useState(false);

    const { gameAddress } = useParams();


    const [count, setCount] = useState(4);
    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count - 1);
        }, 1000);

        if (count === 0) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [count])


    const handleClick = async () => {
        try {
            setIsSelected(true);
            const bodyData = { gameAddress }
            const response = await axiosInstance.post('/api/game/uno-card-game/call-uno', bodyData);
            
            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false);
                hotToast.error(responseData.message, { duration: 1000 });
            }


        } catch (error) {
            hotToast.error(error.message, { duration: 4000 });
        }
    }

    if (count == 0) return null;
    if (isSelected) return null;

    return (
        <button id="animatedButton" onClick={handleClick} className='animate-bounce w-1/2 py-2.5 font-extrabold rounded-md bg-yellow-400 z-50'>
            Call UNO in {count}
        </button>
    );
}

export default UnoCallButton
