import React from 'react'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import { useSelector } from 'react-redux'
import hotToast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../../../Config/axios'
import SolToUSD from '../../../../../CommonComponent/SolToUSD'
import { formatAddress } from '../../../../../Config/function'
import Chat from '../../../../ChatingComponent/chatingComponent'

const GameResult = ({ gameInfo, fetchGameInfo }) => {
  const { gameAddress } = useParams()
  const navigate = useNavigate()
  const userData = useSelector(state => state.auth.userData)

  const { result: gameResult } = gameInfo || {}

  const allPlayers = gameResult?.players || []
  const winner = gameResult?.winner || {}

  const myProfile = allPlayers.find(player => player.userId === userData.id)
  let allNewPlayers = []
  if (myProfile) {
    const otherPlayers = allPlayers.filter(player => player.userId !== userData.id)
    allNewPlayers = [myProfile, ...otherPlayers]
  } else {
    allNewPlayers = allPlayers
  }


  const getClassName = (index) => {
    let className = 'absolute flex flex-col items-center top-5 left-5'

    if (allNewPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

    if (allNewPlayers.length == 2) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

      if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

    } else if (allNewPlayers.length == 3) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

    } else if (allNewPlayers.length == 4) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
    } else if (allNewPlayers.length == 5) {

      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  translate-x-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-x-1/2'
      if (index == 4) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

    } else if (allNewPlayers.length == 6) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
      if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
      if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

    } else if (allNewPlayers.length == 7) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
      if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
      if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
    } else if (allNewPlayers.length == 8) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
      if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
      if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

    } else if (allNewPlayers.length == 9) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
      if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
      if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
      if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
      if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
    }
    return className
  }


  const handleJoinGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/5-card-poker/join', { gameAddress })


      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message)
      } else {
        const data = { slug: '5-card-poker', gameAddress }
        localStorage.setItem('currentJoinedGame', JSON.stringify(data))
        hotToast.success('Joined', { position: 'bottom-center' })
      }

    } catch (error) {
      hotToast.error('Error in joining game');
    }
  }

  const handleLeaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/5-card-poker/leave', { gameAddress })
      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message)
      } else {
        navigate('/all-game-page')
      }

    } catch (error) {
      hotToast.error('Error in leaving game')
    }
  }

  const enableFullScreen = () => {
    try {

      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else {
          hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
        }
        return;
      }

      const element = document.getElementById('five_card_poker_screen');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else {
        hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
      }
    } catch (error) {
      hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
    }
  }

  const RenderGameWinner = () => {
    if (!winner) return null;

    // const { profileUrl, address } = winner
    return (
      <div className='flex flex-row items-center justify-between w-full container gap-11'>
        {winner.map(item => {
          return <div className='flex flex-col items-center justify-center'>
            <span className='text-white font-bold text-2xl mt-2 mb-2'>Winner</span>
            <div className='hi-lo-player flex items-center flex-col	'>
              {<img src={item.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-12 w-12 border border-white p-1/2 hi_lo_player__profile_active`} />}
              <span className='hi-lo-player__balance text-white font-bold mt-3'>{item.userName ? item.userName : formatAddress(item.address, 6)}</span>
              <div className='flex items-center justify-center flex-row text-white'>
                <span className='text-sm'>{Number(item.amount).toFixed(2)} Sol</span>
                <span className='text-xs ml-2'>
                  (<SolToUSD amount={item.amount} symbol={'USD'} />)
                </span>
              </div>

            </div>
          </div>
        })}
      </div>
    )

  }

  if (!gameInfo) return <div>Loading...</div>


  return (
    <div id='five_card_poker_screen' className='relative game  flex flex-col items-center h-full'>
      <MenuActions isInGame={false} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />

      <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-5  container rounded-full '>
        <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

          <div className=" w-full h-full rounded-full border-2" style={{
            backgroundColor: '#222222'
          }}>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <RenderGameWinner />
            </div>

            {allNewPlayers.map((player, index) => {
              return (
                <div key={index} className={getClassName(index)}>
                  <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                    <Player index={index} totalPlayers={allNewPlayers.length} winner={winner} player={player} />
                  </div>
                </div>
              );

            })}
          </div>
        </div>
      </div>

      <div className='absolute bottom-5 left-1/2 transform -translate-x-1/2'>
        <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'> Press here to play/continue... </button>
      </div>
    </div>
  )
}

export default GameResult
