import React, { useEffect, useRef, useState } from 'react'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { useSelector } from 'react-redux';
import coins from '../../../../../assets/img/games/coins_transparent.png';
import hotToast from 'react-hot-toast';
import axiosInstance from '../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import PotMoney from '../Common/PotMoney';
import SevenCardPokerActionButtons from './SevenCardPokerActionButtons';
const PlayGame = ({ gameInfo, socket }) => {
    gsap.registerPlugin(Flip);
    const usersRef = useRef([])

    const [gameStatus, setGameStatus] = useState(gameInfo?.round || 'starting')
    const [selectedCards, setSelectedCards] = useState([])
    const { gameAddress } = useParams()
    const userData = useSelector(state => state.auth.userData)

    const [gameBetStatus, setGameBetStatus] = useState({
        buttons: [],
        currentPlayer: 0,
        betAmount: 0,
        pot: 0,
        maxBet: 0,
        userBalances: [],
        foldedPlayers: [],
        leftPlayers: [],
        playerCards: [],
        timer: 0
    })

    const [showdownStatus, setShowdownStatus] = useState({
        currentPlayer: 0,
    })

    const allPlayers = gameInfo.players || []

    let allNewPlayers = []
    const myProfile = allPlayers.find(player => player.userId === userData.id)
    if (myProfile) {
        const otherPlayers = allPlayers.filter(player => player.userId !== userData.id)
        allNewPlayers = [myProfile, ...otherPlayers]
    } else {
        allNewPlayers = allPlayers
    }

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allNewPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (allNewPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allNewPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allNewPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allNewPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-x-1/2'
            if (index == 4) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allNewPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
            if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allNewPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
        } else if (allNewPlayers.length == 8) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

        } else if (allNewPlayers.length == 9) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
        }
        return className
    }

    const servedCardsToPlayers = (playerCard) => {
        const totalPlayers = allPlayers.length;
        const dealer = document.getElementById('stws_pot_money_box');
        const totalRounds = playerCard.cards || 1;
        const dealInterval = 300; // Time between each card deal

        for (let round = 0; round < totalRounds; round++) {
            allPlayers.forEach((_, index) => {
                setTimeout(() => {
                    const card = document.createElement('div');
                    card.className = 'card h-16 w-10 bg-gradient-to-r from-red-300 border-2 border-gray-900 to-red-300 border border-2 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
                    dealer.appendChild(card);

                    const dealerRect = dealer.getBoundingClientRect();
                    const userIndex = index % totalPlayers;
                    const userRect = usersRef.current[userIndex].getBoundingClientRect();
                    const cardRect = card.getBoundingClientRect();

                    const x = userRect.left + (userRect.width - cardRect.width) / 2 - dealerRect.left + window.scrollX;
                    const y = userRect.top + (userRect.height - cardRect.height) / 2 - dealerRect.top + window.scrollY;

                    gsap.to(card, {
                        duration: 0.5,
                        x: x,
                        y: y,
                        ease: "power2.inOut",
                        onComplete: () => {
                            card.remove();
                        }
                    });
                }, round * totalPlayers * dealInterval + index * dealInterval);
            });
        }

    }

    const fetchPlayerBetAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/7-card-poker/bet-status', { gameAddress });

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setGameBetStatus(responseData.data);
            }

        } catch (error) {
            hotToast.error('Error while fetching player bet action', { duration: 3000, position: 'bottom-center' })
        }

    }

    const fetchPlayerShowdownAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/7-card-poker/showdown-status', { gameAddress });

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setShowdownStatus(responseData.data);
            }


        } catch (error) {
            hotToast.error('Error while fetching player bet action', { duration: 3000, position: 'bottom-center' })
        }
    }

    const animateFlowToPot = (playerId, targetId, isCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);
        
        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null

        if (isCard) {
            card = document.createElement('span');
            card.className = 'card h-20 w-14 bg-red-400 rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.body.appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    useEffect(() => {
        if (!socket) return;
        socket.on('betPlayed', (data) => {
            const { isBetFinished, userId, isRoundStarted, round } = data;
            animateFlowToPot(`stws_player_${data.userId}`, 'stws_pot_money_box');
            if (isBetFinished) {
                fetchPlayerBetAction();
                setGameStatus("betting");
                servedCardsToPlayers({ cards: 3 });
                setTimeout(() => {
                    fetchPlayerBetAction();
                }, 3 * 300 * allPlayers.length);
            } else if (!isRoundStarted) {
                fetchPlayerBetAction();
            } else if (isRoundStarted) {
                if (data.round == 'showdown') {
                    fetchPlayerBetAction();
                    fetchPlayerShowdownAction();
                } else {
                    servedCardsToPlayers({ cards: 1 });
                    setTimeout(() => {
                        fetchPlayerBetAction();
                    }, 1 * 300 * allPlayers.length);
                }

            }
        })

        socket.on('opponentLeft', (data) => {
            fetchPlayerBetAction();
        });

        socket.on('showdownPlayed', (data) => {
            const { isFinished } = data;
            fetchPlayerShowdownAction();
        });

        return () => {
            socket.off('betPlayed');
            socket.off('opponentLeft');
            socket.off('showdownPlayed');
        }
    }, [gameInfo])

    useEffect(() => {
        fetchPlayerBetAction();
    }, [gameInfo, gameStatus])

    useEffect(() => {
        if (gameBetStatus?.round == 'showdown') {
            fetchPlayerShowdownAction();
        }
    }, [gameBetStatus])






    let userBalances = gameBetStatus?.userBalances || []
    let pot = gameBetStatus?.pot || 0
    let maxBet = gameBetStatus?.maxBet || 0
    let foldedPlayers = gameBetStatus?.foldedPlayers || []
    let leftPlayers = gameBetStatus?.leftPlayers || []
    let currentPlayer = gameBetStatus?.currentPlayer || 0
    let playerCards = gameBetStatus?.playerCards || []
    let timer = gameBetStatus?.timer || 30
    if (gameBetStatus?.round == 'showdown') {
        currentPlayer = showdownStatus?.currentPlayer || 0
        timer = showdownStatus?.timer || 30
    }

    const handleSelectedCardSubmit = async () => {
        if (selectedCards.length < 5) return hotToast.error('Please select 5 cards', { duration: 2000, position: 'bottom-center', id: 'showdown_error' })

        try {
            const bodyData = { gameAddress, cards: selectedCards }
            const response = await axiosInstance.post('/api/game/7-card-poker/showdown', bodyData);
            
            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {

            }
            
        } catch (error) {
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }
    }

    const handleToggleCard = (index, playerUserId) => {
        if (gameBetStatus.round != 'showdown') return;
        if (playerUserId !== userData.id) return;
        if (currentPlayer !== userData.id) return;
        if (selectedCards.includes(index)) {
            setSelectedCards(selectedCards.filter(cardIndex => cardIndex !== index))
        } else {
            if (selectedCards.length >= 5) return hotToast.error('You can only select 5 cards', { duration: 2000, position: 'bottom-center' })
            setSelectedCards([...selectedCards, index])
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('seven_card_poker_table');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }
    const handleLeaveGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo?.gameAddress }
            const response = await axiosInstance.post('/api/game/7-card-poker/leave', bodyData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                hotToast.success(responseData.message, { duration: 3000, position: 'bottom-center' })
                window.location.href = '/all-game-page'
            }

        } catch (error) {
            console.log(error)
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }

    }



    const dealerPosition = gameInfo?.dealerPosition || 0

    const dealerUserId = gameInfo['players'][dealerPosition]?.userId || 0


    return (
        <>
            <div id='seven_card_poker_table' className='relative game  flex flex-col items-center h-full container'>
                <MenuActions isInGame={myProfile} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
                <div className=' h-[60%] p-2 bg-gray-200 border-2  mt-10 container rounded-full '>
                    <div className="relative p-2 h-full rounded-full border-2 bg-slate-900">
                        <div className="h-full rounded-full border-2" style={{
                            backgroundColor: '#222222'
                        }}>
                            <div id='stws_pot_money_box' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <PotMoney pot={Number(pot).toFixed(2)} />
                            </div>
                            {allNewPlayers.map((player, index) => {
                                return (
                                    <div id={`stws_player_${player.userId}`} key={index} className={getClassName(index)} ref={el => usersRef.current[index] = el}>
                                        <div className='stws-lo-player flex items-center flex-col shadow-2xl'>
                                            <Player timer={timer} handleToggleCard={handleToggleCard} selectedCards={selectedCards} showDealer={dealerUserId === player.userId} foldedPlayers={foldedPlayers} leftPlayers={leftPlayers} userBalances={userBalances} playerCards={playerCards}
                                                gameBetStatus={gameBetStatus} pot={pot} maxBet={maxBet} gameStatus={gameStatus} totalPlayers={allNewPlayers.length} index={index} key={player.userId} player={player} currentPlayer={currentPlayer} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </div>
                {(gameStatus != 'starting') && (gameBetStatus.round != 'betting') && (userData?.id === currentPlayer) && <div className='absolute bottom-10 h-auto flex w-full items-center justify-center'>
                    <SevenCardPokerActionButtons maxBet={maxBet} pot={pot} gameStatus={gameStatus} gameBetStatus={gameBetStatus} handleSelectedCardSubmit={handleSelectedCardSubmit} currentPlayer={currentPlayer}/>
                </div>}
            </div>

        </>
    )
}

export default PlayGame
