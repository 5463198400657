import React from 'react'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import { formatAddress } from '../../../../../Config/function'

const Player = ({ player, folded, left }) => {

  const newPlayerCard = player.cards || []

  return (
    <div className={`hi-lo-player flex items-center flex-col	`}>


      <div className='relative cards flex flex-row items-center justify-between gap-2 mb-2 '>
        {player.isFolded && <img src={FoldImage} alt='fold' className='w-32 h-20' />}
        {player.isLeft && !player.isFolded && <img src={LeftImage} alt='left' className='w-32 h-20' />}
        {!player.isLeft && !player.isFolded && <img src={player.profileUrl} alt='left' className='w-16 h-16 rounded-full border-2 border-white p-1' />}
      </div>
      <div className={``}>
        <div className={`flex flex-col items-center justify-center bg-black font-extrabold px-5 text-center border-2 rounded-md `}>
          <div className='name text-white mb-1 text-sm'>{player.userName ? player.userName : formatAddress(player.address, 3)}
      
          </div>

        </div>
      </div>
    </div>
  )
}

export default Player
