import React, { useState, useEffect } from "react";
import Sidebar from "../Component/Page/Sidebar";
import Header from "../Component/Shared/Header";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import axiosInstance from "../Config/axios";
import { useDispatch } from "react-redux";
import { convertToUSD } from "../redux/authentication";
// import Banner from "../Component/Shared/Banner"; // Import the Banner component

const Layout = ({ component, hideSidebar, hideNavbar }) => {
  const location = useLocation();
  const params = new useParams();
  const dispatch = useDispatch();


  const handleUserGameExit = async () => {
    try {
      let currentJoinedGame = localStorage.getItem("currentJoinedGame");
      if (!isEmpty(currentJoinedGame)) {
        currentJoinedGame = JSON.parse(currentJoinedGame);
      } else {
        return;
      }

      const { gameAddress, slug } = currentJoinedGame;
      let url = ''
      if (slug == 'rock-paper-scissors') url = '/api/game/leave'
      if (slug == 'poker') url = '/api/game/poker/leave'
      if (slug == 'seven-twenty-seven') url = '/api/game/seven-twenty-seven/leave'
      if (slug == 'highlow') url = '/api/game/highlow/leave'
      if (slug == '5-card-poker') url = '/api/game/5-card-poker/leave'
      if (slug == '7-card-poker') url = '/api/game/7-card-poker/leave'
      if (slug == 'uno-card-game') url = '/api/game/uno-card-game/leave'

      if (isEmpty(url)) return;
      let userLocation = (localStorage.getItem("location"));
      if (!userLocation) return;
      userLocation = JSON.parse(userLocation);
      
      const { currentPath, previousPath } = userLocation;
      const currentLocation = params.slug ? location.pathname.replace(`/${params.slug}`, '') : location.pathname;
      if (currentLocation == currentPath) return;
      
      await axiosInstance.post(url, { gameAddress });
      localStorage.removeItem("currentJoinedGame");

    } catch (error) {
      console.log(error);
    }
  }


  const handleLocationChange = (location) => {
    try {
      let localLocation = localStorage.getItem("location");
      if (!localLocation) {
        let currentPath = location.pathname;
        let previousPath = location.pathname;
        localStorage.setItem("location", JSON.stringify({ currentPath, previousPath }));
      } else {
        localLocation = JSON.parse(localLocation)
        let { currentPath, previousPath } = localLocation;
        previousPath = currentPath;
        currentPath = params.slug ? location.pathname.replace(`/${params.slug}`, '') : location.pathname;

        localStorage.setItem("location", JSON.stringify({ currentPath, previousPath }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // When user loction is change then we have to check if user is active in any game or not if he is active then we have to remove him from the game
    handleUserGameExit();
    handleLocationChange(location);
  }, [location]);

  let isInGame = false
  if (location.pathname.includes('play/uno-card-game')) {
    isInGame = true
  } else if (location.pathname.includes('play/rock-paper-scissors')) {
    isInGame = true
  } else if (location.pathname.includes('play/poker')) {
    isInGame = true
  } else if (location.pathname.includes('play/hi-lo-card-game')) {
    isInGame = true
  } else if (location.pathname.includes('play/5-card-poker')) {
    isInGame = true
  } else if (location.pathname.includes('play/7-card-poker')) {
    isInGame = true
  } else if (location.pathname.includes('play/7-27-poker')) {
    isInGame = true
  }

  
  useEffect(() => {
    dispatch(convertToUSD({ amount: 1 }));

    setInterval(() => {
      if (isInGame) return;
      dispatch(convertToUSD({ amount: 1 }));
    }, 5000 * 60);
  }, [location.pathname]);


  return (
    <React.Fragment>
      {!hideNavbar && <Header />}
      {!hideSidebar ? (
        <div className="flex px-1 m-1 gap-7">
          <div className="h-[85vh]">
            <Sidebar />
          </div>
          <div className="w-[100%]">{component}</div>
        </div>
      ) : (
        <div className="w-full h-screen">{component}</div>
      )}
      {/* {showBanner && <Banner />} Conditionally render the banner */}
    </React.Fragment>
  );
};

export default Layout;


// const Banner = () => {
//   const [isVisible, setIsVisible] = useState(true);

//   useEffect(() => {
//     // Set a timeout to hide the banner after 5 seconds
//     const timer = setTimeout(() => setIsVisible(false), 1000);
    
//     // Clear the timer when the component is unmounted
//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     isVisible && (
//       <div className="fixed bottom-0 left-0 right-0 bg-blue-500 text-white text-center py-2 shadow-lg">
//         <p>This is your banner message!</p>
//       </div>
//     )
//   );
// };


