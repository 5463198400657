import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import avatarPlaceholder from '../../../Image/logo.png';
import axiosInstance from '../../../Config/axios';
import Toast from "../../../CommonComponent/Toast";
import { Slide, toast } from 'react-toastify';
import { StakingApp } from '../../Authentication/Context/StakingApp';
import { fetchUserDate } from '../../../redux/userProfile';
import Cookies from 'js-cookie';
Modal.setAppElement('#root');

const EditProfile = () => {
  const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const { updateProfile } = useContext(StakingApp);
  const { data } = useSelector((state) => state.userData);
  const [newName, setNewName] = useState('');
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [preview, setPreview] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewProfilePic(file);
    setPreview(URL.createObjectURL(file));
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewName('');
    setNewProfilePic(null);
    setPreview('');
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!newName && !newProfilePic) {
      console.error('Name or profile picture is missing');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('userName', newName);
    formData.append('profilePic', newProfilePic);

    try {
      const response = await axiosInstance.post('/user/update-profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success(<Toast message={"Changes updated successfully"} status="success" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
      // console.log(response.data.status);
      // closeModal();
      // updateProfile();
      const { data: responseData } = response
      const newData = { ...userData,  profileUrl: responseData.data.profileUrl };
      if (newName) {
        newData.userName = newName;
      }
      localStorage.setItem("userData", JSON.stringify(newData));
      const newToken = responseData.data.newToken;
      const expirationTime = 1;
      Cookies.set('token', newToken, { expires: expirationTime });
      dispatch(fetchUserDate());
      window.location.reload();

    } catch (error) {
      console.error('There was an error!', error);
      toast.error(<Toast message={error.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-full text-gray-900">
      <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg w-full text-center transform transition duration-500 hover:scale-105">
        <div className="relative inline-block">
          <img
            src={data?.profileUrl}
            alt="Avatar"
            className="h-32 w-32 rounded-full cursor-pointer mx-auto mb-4 border-4 border-[#FFD700] shadow-lg"
            onClick={openModal}
          />
          <span
            className="absolute bottom-0 right-0 bg-[#FFD700] text-white text-xs font-bold py-1 px-2 rounded-full cursor-pointer shadow-sm"
            onClick={openModal}
          >
            Edit
          </span>
        </div>
        <p className="text-2xl font-bold mb-4 text-[#FFD700]">{data?.userName}</p>

        <div className="mt-8 flex justify-center gap-4">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full shadow-md focus:outline-none focus:shadow-outline transition duration-300 transform hover:scale-105">
            Play Poker
          </button>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full shadow-md focus:outline-none focus:shadow-outline transition duration-300 transform hover:scale-105">
            Join Tournament
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Profile"
        className="p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto mt-20 transform transition duration-500"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold mb-4 text-blue-600">Edit Profile</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              New Name <i>(Optional)</i>
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter New Name"
              value={newName}
              onChange={handleNameChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition duration-300"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profilePic">
              Change Profile Picture <i>(Optional)</i>
            </label>
            <input
              type="file"
              id="profilePic"
              onChange={handleFileChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition duration-300"
            />
            {preview && (
              <img src={preview} alt="Preview" className="mt-4 h-32 w-32 rounded-full border-4 border-blue-500 shadow-md" />
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline shadow-lg transition duration-300 transform hover:scale-105"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditProfile;
