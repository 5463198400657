import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import axiosInstance from '../../../Config/axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import JoinGameModal from '../../../CommonComponent/JoinGameModal';
import SolToUSD from '../../../CommonComponent/SolToUSD';
import { isEmpty } from 'lodash';
import { formatAddress } from '../../../Config/function';
import { useSelector } from 'react-redux';

const GameViewPage = () => {
    const focusRef = useRef();
    const userData = useSelector((state) => state.auth.userData);
    const [open, setOpen] = useState(false);
    const [joinModalInfo, setJoinModalInfo] = useState({});
    const [gameInfo, setGameInfo] = useState({});
    const { slug } = useParams();
    const navigate = useNavigate();

    const fetchGameInfo = async () => {
        try {
            const bodyData = { slug };
            const response = await axiosInstance.post("/api/game/get", bodyData);
            const { data } = response;

            if (data.status) {
                setGameInfo(data.data);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error("Failed to fetch game info");
        }
    };

    useEffect(() => {
        fetchGameInfo();
    }, [slug]);

    const getGameAdr = (game) => {
        axiosInstance.post('/api/game/create', { gameId: game?.id })
            .then((res) => {
                if (res.data.status) {
                    toast.success('Table created successfully')
                    const data = { slug: game.slug, gameAddress: res.data.data.gameAddress };
                    localStorage.setItem('currentJoinedGame', JSON.stringify(data));
                    navigate(`/play/${game.slug}/${res.data.data.gameAddress}`);
                } else {
                    toast.error(res.data.message)
                }
            });
    };

    const handleJoinModal = (game) => {
        setOpen(false);
        setJoinModalInfo(game);
    };

    const handleJoinGameSubmit = (values) => {
        if (values.slug === 'poker') {
            const data = { slug: values.slug, gameAddress: values.gameAddress };
            localStorage.setItem('currentJoinedGame', JSON.stringify(data));
        }
        navigate(`/play/${values.slug}/${values.gameAddress}`);
    };


    const joinAnonymousGame = async (game) => {
        try {
            const bodyData = { gameId: game.id, isPrivate: 1 };
            let url = ''
            if (game.slug === 'poker') url = '/api/game/poker/join-anonymous'
            if (slug == 'rock-paper-scissors') url = '/api/game/join-anonymous'
            if (slug == '7-27-poker') url = '/api/game/seven-twenty-seven/join-anonymous'
            if (slug == 'hi-lo-card-game') url = '/api/game/highlow/join-anonymous'
            if (slug == '5-card-poker') url = '/api/game/5-card-poker/join-anonymous'
            if (slug == '7-card-poker') url = '/api/game/7-card-poker/join-anonymous'
            if (slug == 'uno-card-game') url = '/api/game/uno-card-game/join-anonymous'

            const response = await axiosInstance.post(url, bodyData);

            const { data: resposneData } = response;

            if (!resposneData.status) {
                toast.error(resposneData.message, { position: 'bottom-center', id: 'join-anonymous' });
            } else {
                navigate(`/play/${game.slug}/${resposneData.data.gameAddress}`);
            }

        } catch (error) {
            toast.error("Failed to join game");
        }
    }


    const RenderText = () => {
        if (!gameInfo?.opponentPlayers) return '(No anonymous)';
        if (gameInfo?.opponentPlayers.length === 0) return '(No anonymous)';

        const myProfile = gameInfo?.opponentPlayers.find((player) => player.address === userData?.address);
        if (gameInfo?.opponentPlayers.length > 1) {

            if (myProfile) {

                const player = gameInfo?.opponentPlayers.find((player) => player.address !== userData?.address);
                return `(${player?.userName ? player?.userName : formatAddress(player?.address, 3)} is waiting...)`;
            } else {
                return `(${gameInfo?.opponentPlayers?.length} players waiting)`;
            }
        }
        if (Object.keys(gameInfo?.opponentPlayers[0]).length == 0) return '(No anonymous)';

        const player = gameInfo?.opponentPlayers[0];

        if (player.address == userData?.address) return null

        return `(${player?.userName ? player.userName : formatAddress(player?.address, 3)} is waiting...)`;
    }

    return (
        <div className="w-full px-4 py-6 bg-gray-900  text-white">
            <div className="relative h-64 bg-gray-800 rounded-lg overflow-hidden">
                <img src={gameInfo?.image} alt={gameInfo?.title} className="w-full h-full object-cover" />
                <ArrowBackIcon
                    className="absolute top-4 left-4 text-white cursor-pointer bg-black rounded-full p-1"
                    onClick={() => navigate(-1)}
                />
                <div className="absolute bottom-4 left-4 text-white bg-black bg-opacity-50 p-3 rounded-lg">
                    <h1 className="text-2xl font-bold">{gameInfo?.title}</h1>
                    <p className="mt-1">{gameInfo?.description}</p>
                </div>
            </div>

            <div className="mt-6">
                <p className="text-lg font-semibold">Minimum Bet: {gameInfo?.minimumBet} Sol
                    <span className="italic font-semibold ml-2">
                        (<SolToUSD amount={gameInfo?.minimumBet} symbol={"USD"} />)
                    </span>
                </p>
                <p className="mt-1">Minimum Players: {gameInfo?.minPlayers}</p>
                <p className="mt-1">Maximum Players: {gameInfo?.maxPlayers}</p>
                {gameInfo?.rules && (
                    <div className="mt-4 p-4 bg-gray-800 rounded-lg">
                        <h2 className="text-xl font-semibold">Game Rules</h2>
                        <p className="mt-2">{gameInfo?.rules}</p>
                    </div>
                )}
            </div>

            <div className="fixed bottom-5 right-0 w-full flex justify-center items-center gap-4">
                <button
                    className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l rounded-lg px-5 py-3"
                    onClick={() => setOpen(true)}
                    ref={focusRef}
                >
                    Play Now <PlayArrowIcon className="ml-2" />
                </button>
            </div>

            <BottomSheet
                open={open}
                onDismiss={() => setOpen(false)}
                className="p-4 bg-gray-800 rounded-t-lg"
            >
                

                <div className="mt-6 pb-5 flex flex-col gap-4  px-4">
                    <button
                        className="bg-gradient-to-r from-purple-900 to-purple-900 text-white rounded-lg px-5 py-3 text-xs font-bold disabled:opacity-70 disabled:cursor-not-allowed"
                        onClick={() => joinAnonymousGame(gameInfo)}
                        disabled={isEmpty(gameInfo?.opponentPlayers)}
                    >
                        Join Table In Progress <span className='ml-1 text-white '> <RenderText /> </span>
                    </button>
                    <button
                        className="bg-gradient-to-r from-amber-300 to-yellow-400 text-black rounded-lg px-5 py-3 text-xs font-bold"
                        onClick={() => getGameAdr(gameInfo)}
                    >
                        Create Table
                    </button>
                    <button
                        className="bg-gradient-to-r from-purple-800 to-purple-800 text-white rounded-lg px-5 py-3 text-xs font-bold"
                        onClick={() => handleJoinModal(gameInfo)}
                    >
                        Join With ID <PlayArrowIcon className="ml-2" />
                    </button>
                </div>
            </BottomSheet>

            {!isEmpty(joinModalInfo) && (
                <JoinGameModal
                    data={joinModalInfo}
                    show={setJoinModalInfo}
                    handleSubmit={handleJoinGameSubmit}
                    handleClose={() => setJoinModalInfo(null)}
                />
            )}
        </div>
    );
};

export default GameViewPage;
