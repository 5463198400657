import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import hotToast from 'react-hot-toast';
import axiosInstance from '../../../../../../Config/axios';

const PlayButtons = ({ selectedCards, pot, maxBet, currentMoveInfo }) => {
    const { gameAddress } = useParams()
    const userData = useSelector(state => state.auth.userData)
    const [isSelected, setIsSelected] = useState(false)


    useEffect(() => {
        if (currentMoveInfo?.currentPlayer === userData?.id) {
            setIsSelected(false)
        }
    }, [currentMoveInfo])

    const handleMoveSubmit = async (move) => {

        try {

            let bodyData = { gameAddress, move }
            if (move === 'draw' && selectedCards && selectedCards.length === 0) {
                return hotToast.error('Please select atleast one card', { position: 'bottom-center', duration: 2000 })
            }
            if (selectedCards && selectedCards.length > 4 && move === 'draw') {
                return hotToast.error('You can only draw 4 cards', { position: 'bottom-center', duration: 2000 })
            }

            if (move === 'draw') {
                bodyData = { ...bodyData, selectedCards }
            } else {
                bodyData = { ...bodyData, selectedCards: [] }
            }
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/move', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { position: 'bottom-center', duration: 2000 })
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { position: 'bottom-center', duration: 2000 })
        }
    }

    if (isSelected) return

    return (
        <div className='h-full flex w-1/2 justify-center items-center gap-2'>
            <span onClick={() => handleMoveSubmit('draw')} role='button' className='text-center highlow_action_buttons_high_and_low_button bg-yellow-600 text-white font-bold py-2 px-5 rounded   text-xs'>
                Draw
            </span>
            <span onClick={() => handleMoveSubmit('pass')} role='button' className='text-center highlow_action_buttons_high_and_low_button bg-yellow-600 text-white font-bold py-2 px-5 rounded inset-10 text-xs'>
                Pass
            </span>
        </div>
    )
}

export default PlayButtons
