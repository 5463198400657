import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBalance } from '../../../redux/balanceSlice';
import bnb from '../../../Image/bbb.png';
import eth from '../../../Image/eth.png';
import sol from '../../../Image/sol.png';
import usdt from '../../../Image/usdt.png';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

const WalletPage = () => {
  const dispatch = useDispatch();
  const { assets, status, error } = useSelector((state) => state.balance);
  console.log(assets, "asset");

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  const getImage = (name) => {
    switch (name) {
      case 'BNB':
        return bnb;
      case 'ETH':
        return eth;
      case 'SOL':
        return sol;
      case 'USDT':
        return usdt;
      default:
        return '';
    }
  };

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 xl:px-10">
      <h1 className="text-3xl font-bold text-[#FFD700] mb-8">Wallet Overview</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {assets.map((asset) => (
          <div
            key={asset.assetId}
            className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between"
          >
            <div className="flex items-center justify-between mb-4">
              <img src={getImage(asset.assetKey)} alt={asset.assetTitle} className="h-12 w-12 mr-2" />
              <h2 className="text-2xl font-bold text-gray-800">{asset.assetTitle}</h2>
            </div>
            <div className="mb-4">
              {asset.chains.map((chain) => (
                <div key={chain.chainId} className='flex justify-between '>
                  <p className="text-gray-600 mb-2">Chain: {asset.assetTitle}</p>
                  <p className="text-gray-600 mb-2">Balance: {chain.balance}</p>
                </div>
              ))}
            </div>
            <div className="bg-gray-100 rounded-lg p-4 text-sm text-gray-600">
              <p className="mb-1">Statistical Overview:</p>
              {/* Add your statistical overview here */}
              <ul>
                <li>Total transactions: 10</li>
                <li>Recent transactions: 3</li>
                <li>Average transaction amount: 5 {asset.assetKey}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletPage;
