import React from "react";
import image1 from "../../../Image/a.jpg";
import image2 from "../../../Image/b.jpg";
import image3 from "../../../Image/c.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

export default function Proparties() {
  const navigate = useNavigate();


  const handleGamePlay = () => {
    return navigate('/play')
  }

  return (
    <div className="flex flex-col">
      <div className="flex w-full justify-between items-center text-white my-5">
        <div className="flex justify-center items-center gap-10 ">
          <span className="border px-3 rounded-full py-2">
            <KeyboardBackspaceIcon onClick={() => navigate("/all-game-page")} />
          </span>
          <p className="font-bold text-white text-xl">Your Dealership:</p>
        </div>
        <div className="flex justify-center items-center gap-10 ">
          <p className="font-bold text-white text-xl">Last Win: $</p>
          <p className="font-bold text-yellow-500 text-xl">1548</p>
        </div>
      </div>
      <div className="flex justify-between items-center w-full mt-10">
        <div onClick={handleGamePlay}
          className=" relative  flex flex-col bg-black w-[30%]  border-2 border-red-400   items-center h-[700px] rounded-tl-3xl rounded-br-3xl px-10"
          style={{
            backgroundImage: `url(${image1})`,
            backgroundSize: "cover",
          }}
        >
          <div className="h-32 w-full mt-10 bg-[#132128] border border-[#578FC3] flex justify-center items-center">
            <div className="flex justify-center items-center">
              <p className="text-yellow-500 font-bold text-2xl">Initial</p>
            </div>
          </div>
        </div>
        <div
          className=" relative  flex flex-col bg-black w-[30%]  border-2 border-red-400   items-center h-[700px] rounded-tl-3xl rounded-br-3xl px-10 "
          style={{
            backgroundImage: `url(${image2})`,
            backgroundSize: "cover",
          }}
        >
          {" "}
          <div className="h-32 w-full mt-10 bg-[#132128] border border-[#578FC3] flex justify-center items-center">
            <div className="flex justify-center items-center">
              <p className="text-yellow-500 font-bold text-2xl">Advance</p>
            </div>
          </div>
        </div>
        <div
          className="relative  flex flex-col bg-black w-[30%]  border-2 border-red-400   items-center h-[700px] rounded-tl-3xl rounded-br-3xl px-10"
          style={{
            backgroundImage: `url(${image3})`,
            backgroundSize: "cover",
          }}
        >
          {" "}
          <div className="h-32 w-full mt-10 bg-[#132128] border border-[#578FC3] flex justify-center items-center">
            <div className="flex justify-center items-center">
              <p className="text-yellow-500 font-bold text-2xl">Pro</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
