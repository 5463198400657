import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import Card from "../../PokerTest/Components/Pages/Cards/index"
import { useSelector } from 'react-redux'
const Player = ({ player, gameResult }) => {
  const userData = useSelector(state => state.auth.userData)

  const highLightCard = (card) => {
    if (!gameResult) return false;
    const { winner, winnerCombination } = gameResult;
    if (!winnerCombination) return false;
    const isExists = winnerCombination['Cards'].filter(item => item['Suit'] === card['Suit'] && item['Rank'] === card['Rank'])
    return isExists.length > 0;

  }

  const isAllFolded = () => {
    if (!gameResult) return false;
    const { allFolded, winner } = gameResult;
    if (allFolded) return true;
  }

  const winner = () => {
    if (!gameResult) return false;
    const { winner } = gameResult;
    return winner;

  }



  return (
    <div className={`relative flex items-center rounded-lg space-x-4 border-white `}>
      {/*  */}
      <div className='relative flex flex-col items-center '>


        <div className='w-full justify-center  flex items-center z-20 gap-3'>
          {!player.isFolded && !player.isLeft && player['Hand'].map((card, index) => {
            return <Card key={index} card={card} className={`w-12 h-16 rounded-md ${highLightCard(card) ? 'scale-125 border-purple-500 border-2 shadow-purple-400 shadow-2xl' : 'opacity-70'}`} />
          })}
          {player.isFolded && !player.isLeft && <img src={FoldImage} className='block  mx-auto w-full h-16' />}
          {player.isLeft && !player.isFolded && <img src={LeftImage} className='block  mx-auto w-full h-16' />}

          
        </div>

        <div className={`${winner() == player.address && "p-2 bg-green-400 rounded-md"}`}>
          <div className={`flex flex-row py-1.5 border-2 rounded-md shadow-lg bg-black px-3 z-20 items-center justify-between`} >
            {!player.isFolded && <img src={player?.profileUrl} className="w-8 h-8 rounded-full border-white border" />}
            {player.isFolded && <img src={FoldImage} className="w-8 h-8 rounded-full border-white border" />}
            <div className='ml-2'>
              <div className="text-white font-semibold text-xs">{player.userName ? player.userName : player.address == userData.address ? 'You' : formatAddress(player.address, 6)}</div>
              <div className="text-blue-300 text-xs">{
              //  player.userId === userData?.id ? Number(player?.restAmount).toFixed(2) : '***'
              } 
              </div>
            </div>
          </div>
        </div>

      </div>




    </div>
  )
}

export default Player
