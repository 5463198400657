import { useSelector } from 'react-redux'

const SolToUSD = ( { amount, symbol } ) => {
    const solPrice = useSelector(state => state.auth.solPrice)
  
    if (!amount) return 0;
    if (!solPrice) return 0;
  
    return <span>{(amount * solPrice).toFixed(2)} {symbol}</span>
}

export default SolToUSD
