import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import avatarPlaceholder from '../../../Image/logo.png';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import hotToast from 'react-hot-toast';
import axiosInstance from '../../../Config/axios';
import { fetchUserDate } from '../../../redux/userProfile';
import Cookies from 'js-cookie';

Modal.setAppElement('#root');

const EditProfile = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchUserInfo = async () => {
    try {
      const { profileUrl, address: userAddress, userName } = userData;
      formik.setFieldValue('userName', userName);
      formik.setFieldValue('userAddress', userAddress);
      formik.setFieldValue('profileUrl', profileUrl);
    } catch (error) {
      hotToast.error('Failed to fetch user data');
    }
  }


  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleProfileUpdate = async (values) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('userName', values.userName);
      formData.append('profilePic', uploadedImage);
      
      const response = await axiosInstance.post('/user/update-profile', formData);

      const { data: responseData } = response
      if (responseData.status) {
        hotToast.success('Profile updated successfully', { duration : 3000, position: 'bottom-center' });
        localStorage.setItem("userData", JSON.stringify({...userData, userName: values.userName, profileUrl: responseData.data.profileUrl}));
        const newToken = responseData.data.newToken;
        const expirationTime = 1;
        Cookies.set('token', newToken, { expires: expirationTime });

        dispatch(fetchUserDate());
        window.location.reload();
      } else {
        hotToast.error('Failed to update profile', { duration : 3000, position: 'bottom-center' });
      }
      setIsLoading(false);
      
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      hotToast.error('Failed to update profile', { duration : 3000, position: 'bottom-center' });
    }
  }
 
  const formik = useFormik({
    initialValues: {
      userName: '',
      userAddress: '',
      profileUrl: ''
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .matches(/^[a-zA-Z0-9_]*$/, 'No spaces allowed')
        .required('Required'),
    }),
    onSubmit: values => {
      handleProfileUpdate(values);
    }
  })

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file);
    }
  };

  const handleImageClick = () => {
    document.querySelector('input[type="file"]').click();
  }


  return (
    <div className="flex   h-full bg-black text-white">
      <div className=" mx-auto p-6 bg-gray-800 h-full rounded-lg shadow-md w-full">
        <div className="relative inline-block mx-auto">
          <img
            src={uploadedImage ? URL.createObjectURL(uploadedImage) : formik.values.profileUrl ? `${formik.values.profileUrl}` : avatarPlaceholder}
            alt="Avatar"
            className="h-32 w-32 rounded-full border cursor-pointer mx-auto mb-4 border-red-500"
            onClick={handleImageClick}            
          />
          <input accept='image/*' type="file" onChange={handleFileChange} className="hidden" />
        </div>
        <form onSubmit={formik.handleSubmit}>
            <div className='mb-4'>
              <label htmlFor='userName' className='block text-sm font-bold mb-2'>Name</label>
              <input 
                type='text'
                id='userName'
                name='userName'
                value={formik.values.userName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className='text-red-500 text-xs mt-1'>{formik.errors.userName}</div>
              ) : null}
            </div>
            <div className='mb-4'>
              <label htmlFor='userAddress' className='block text-sm font-bold mb-2'>Address</label>
              <input 
                type='text'
                id='userAddress'
                readOnly
                disabled
                name='userAddress'
                value={formik.values.userAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight disabled:text-white focus:outline-none focus:shadow-outline'
              />
              {formik.touched.userAddress && formik.errors.userAddress ? (
                <div className='text-red-500 text-xs'>{formik.errors.userAddress}</div>
              ) : null}
            </div>
            <div className='mb-4'>
              <button disabled={isLoading} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' type='submit'>
                {isLoading ? 'Updating...' : 'Update'}
              </button>
            </div>
        </form>


      </div>

      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Profile"
        className="p-6 bg-white rounded-lg shadow-md max-w-md mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              New name
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter New Name"
              value={newName}
              onChange={handleNameChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profilePic">
              Profile Picture
            </label>
            <input
              type="file"
              id="profilePic"
              onChange={handleFileChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {preview && <img src={preview} alt="Preview" className="mt-4 h-32 w-32 rounded-full border-4 border-red-500" />}
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save Changes
            </button>
          </div>
        </form>
      </Modal> */}
    </div>
  );
};

export default EditProfile;
