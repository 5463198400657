import React from 'react'
import Card from '../../Cards/index'

const CommunityCards = ({ showResult, card, gameResult }) => {


  const highlightCard = (card) => {
    if (!showResult) return false
    if (!gameResult) return false;
    const { winnerCombination } = gameResult
    const isWinnerCard = winnerCombination?.['Cards'].filter(winnerCard => winnerCard.Rank === card.Rank && winnerCard.Suit === card.Suit)

    if (isWinnerCard?.length > 0) return false
    return true
  }



  return (
    <div className='community_cards items-center flex flex-row justify-center gap-2'>
      {card.map((card, index) => {
        return <div key={index} className={`fade-in-up rounded-md ${highlightCard(card) ? 'bg_transparent_dark' : showResult ? 'scale-110 border-purple-500 border-2 shadow-purple-400 shadow-2xl' : ''}`}>
          <Card card={card} className={'h-20 w-14 rounded-md '} />
        </div>
      })}
    </div>
  )

}

export default CommunityCards