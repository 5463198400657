import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
const GameResult = ({ result = 'win' }) => {
  const [countdown, setCountdown] = useState(3);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getEmoji = () => {
    switch (result) {
      case 'win':
        return '😊';
      case 'lose':
        return '😢';
      case 'draw':
        return '😐';
      default:
        return '';
    }
  };


  let resultText = ''
  if (result !== 'draw') {
    resultText = (result === 'win') ? 'You Won' : 'You Lost'
  } else {
    resultText = 'Draw'
  }

  return (
    <div className="fixed bg-gray-800 text-white inset-0 z-90 flex items-center justify-center bg-opacity-90 text-center">
      <div>
        {result == 'win' && <Confetti
          numberOfPieces={500}
          wind={0.01}
          gravity={0.3}
        />}
        
         <div className="text-9xl mb-4">{getEmoji()}</div>
        <div className="text-3xl">{resultText}</div>
        <div className="text-6xl mt-4">Starting in {countdown}...</div> 
      </div>
    </div>
  );
};

export default GameResult;
