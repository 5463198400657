import React, { useEffect, useState } from 'react'

const RenderTimer = ({ gameInfo }) => {

    const [timer, setTimer] = useState(gameInfo.difference)

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer - 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [timer])


    if (!gameInfo) return null;
    if (!gameInfo.difference) return null;

    const Timer = () => {
        if (timer > 0) {
            if (timer < 5) {
                return <span className='text-red-500'>Time Left : {timer}</span>
            }
            if (timer < 10) {
                return <span className='text-yellow-500'>Time Left : {timer}</span>
            } else {
                return <span>Time Left : {timer}</span>
            }
        } else {
            return <span>Time Up</span>
        }
    }


    return (
        <span className='text-white font-bold absolute top-5 left-1/2 -translate-x-1/2 flex flex-col items-center'>
            <span>Opponent Selected</span>
            <Timer />
        </span>
    )
}

export default RenderTimer
