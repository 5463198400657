import React, { useEffect, useState } from 'react'
import AnimatedNumber from "animated-number-react";
const PlayerAmount = ( { amount }) => {

    const [newAmount, setNewAmount] = useState(amount);

    const formatValue = (value) => value.toFixed(2);

    useEffect(() => {
        if (!amount) return;
        setNewAmount(amount)
    }, [amount])


  return (
    <AnimatedNumber
        value={newAmount}
        formatValue={formatValue}
    />
  )
}

export default PlayerAmount
