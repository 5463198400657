import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import agora from 'agora-rtc-sdk-ng';
import 'tailwindcss/tailwind.css';
import moment from 'moment';
import {
  FaSmile,
  FaGift,
  FaMicrophone,
  FaMicrophoneSlash,
  FaComments,
} from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import Draggable from 'react-draggable';
import axiosInstance from '../../Config/axios';


// Initialize the socket connection
const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:3500');
const appId = '39861fd403704cadb2813028035aadbd';

const Chat = ({ userId, roomId}) => {

  let micMuted = true;
  console.log("cg=hat-=ho njh ",roomId)
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [currentUserId, setCurrentUserId] = useState(userId);
  const [notification, setNotification] = useState(''); // Notification text
  const [notificationCount, setNotificationCount] = useState(0); // Notification count for new messages
  const [isOpen, setIsOpen] = useState(false); // Chat is closed by default
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isVoiceChatActive, setIsVoiceChatActive] = useState(false);
  const [token, setToken] = useState(null);
  const [localTrack, setLocalTrack] = useState(null);
  const [users, setUsers] = useState([]);
  const [speakingUser, setSpeakingUser] = useState(null);
  const [userStates, setUserStates] = useState({}); // Track publish state for each user
  const clientRef = useRef(null);
  const localAudioTrack = useRef(null);
  const remoteUsersRef = useRef({});
  const [peerConnection, setPeerConnection] = useState(null);
  
  const uid= currentUserId;
  console.log(users,'speaing user ')
  console.log(userStates,'speaing user 23')



  useEffect(() => {
    
    const initAgora = async () => {
      try {
        const client = agora.createClient({ mode: 'rtc', codec: 'vp8' });
        clientRef.current = client;

        // setUserStates(prevStates => ({ ...prevStates, [uid]: true }));
        // console.log('Local audio track published');

        client.on('user-published', async (user, mediaType) => {
          console.log('User published:', user.uid, mediaType);
          try {
            await client.subscribe(user, mediaType);
            console.log('Subscription success!');
            if (mediaType === 'audio') {
              const remoteAudioTrack = user.audioTrack;
              remoteUsersRef.current[user.uid] = remoteAudioTrack;
              remoteAudioTrack.play();


              setUsers(prevUsers => [...prevUsers, { uid: user.uid, track: remoteAudioTrack }]);
              setUserStates(prevStates => ({ ...prevStates, [user.uid]: true }));
            }
          } catch (error) {
            console.error('Error subscribing to user:', error);
          }
        });

        // Handle users unpublishing their tracks
        client.on('user-unpublished', async (user, mediaType) => {
          console.log('User unpublished:', user.uid, mediaType);
          try {
            if (mediaType === 'audio' && remoteUsersRef.current[user.uid]) {
              remoteUsersRef.current[user.uid].stop();
              delete remoteUsersRef.current[user.uid];
              setUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
              setUserStates(prevStates => ({ ...prevStates, [user.uid]: false }));
            }
          } catch (error) {
            console.error('Error handling user unpublished:', error);
          }
        });

        // Handle users leaving the channel 
        client.on('leave', (user) => {
          console.log('User left:', user.uid);
          try {
            if (remoteUsersRef.current[user.uid]) {
              remoteUsersRef.current[user.uid].stop();
              delete remoteUsersRef.current[user.uid];
              setUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
              setUserStates(prevStates => ({ ...prevStates, [user.uid]: false }));
            }
          } catch (error) {
            console.error('Error handling user left:', error);
          }
        });
        await client.join(appId, String(roomId), token, uid);
        if (!localAudioTrack.current) {
          localAudioTrack.current = await agora.createMicrophoneAudioTrack();
        }
    
            //  localAudioTrack.current.setMuted(micMuted)
        await client.publish([localAudioTrack.current]);

      } catch (error) {
        console.error('Error joining the room:', error);
      }
    };

    initAgora();

    return () => {
      const client = clientRef.current;
      if (client) {
        client.leave().then(() => console.log('Left the room')).catch(console.error);
      }
      if (localTrack) {
        localTrack.close();
      }
    };
  }, [token]);

  useEffect(()=>{
    const fetchGameList = () => {
      axiosInstance
        .post("/auth/agora-auth-token",{ userId: userId, channelName: roomId })
        .then((res) => {
          setToken(res.data.data.token);
          console.log(res.data.data)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchGameList();

  },[])

  useEffect(() => {
    socket.on('receiveMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (message.userId !== currentUserId) {
        setNotification(`New message from ${message.userName}`);
        setNotificationCount((prevCount) => prevCount + 1); // Increase notification count
      }
    });

 
    socket.emit('joinRoom', { roomId });

    return () => {
      socket.off('receiveMessage');
    };
  }, [roomId, currentUserId]);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const handleSendMessage = () => {
    if (inputValue.trim() && currentUserId) {
      const message = {
        userId: userId,
        userName: userData?.userName,
        userprofileUrl: userData?.profileUrl,
        content: inputValue,
        roomId,
        timestamp: new Date(),
      };
      socket.emit('sendMessage', message);
      setInputValue(''); // Clear input after sending
    }
  };

  const addEmoji = (emoji) => {
    setInputValue((prev) => prev + emoji);
    setEmojiPickerOpen(false);
  };

  useEffect(() => {
    const chatWindow = document.querySelector('.messages');
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [messages]);

  const startVoiceChat = async () => {
    
      await localAudioTrack.current.setEnabled(true);
 
    setIsVoiceChatActive(false);
  };

  const stopVoiceChat = async () => {
  
      await localAudioTrack.current.setEnabled(false);
    
    setIsVoiceChatActive(true);
  };

  //   try {
  //     if (!stream) {
  //       console.error('No local stream available.');
  //       return;
  //     }

  //     const pc = new RTCPeerConnection(rtcConfig);

  //     // Send audio stream
  //     stream.getTracks().forEach((track) => {
  //       pc.addTrack(track, stream);
  //     });

  //     pc.onicecandidate = (event) => {
  //       if (event.candidate) {
  //         socket.emit('ice-candidate', {
  //           candidate: event.candidate,
  //           roomId,
  //         });
  //       }
  //     };

  //     pc.ontrack = (event) => {
  //       setRemoteStream(event.streams[0]);
  //     };

  //     setPeerConnection(pc);

  //     // Create offer
  //     const offer = await pc.createOffer();
  //     await pc.setLocalDescription(offer);

  //     socket.emit('offer', {
  //       offer: pc.localDescription,
  //       roomId,
  //     });
  //   } catch (error) {
  //     console.error('Error during WebRTC initiation:', error);
  //   }
  // };

  // const handleReceiveOffer = async (data) => {
  //   try {
  //     const pc = new RTCPeerConnection(rtcConfig);

  //     pc.onicecandidate = (event) => {
  //       if (event.candidate) {
  //         socket.emit('ice-candidate', {
  //           candidate: event.candidate,
  //           roomId,
  //         });
  //       }
  //     };

  //     pc.ontrack = (event) => {
  //       setRemoteStream(event.streams[0]);
  //     };

  //     setPeerConnection(pc);

  //     await pc.setRemoteDescription(new RTCSessionDescription(data.offer));

  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setLocalStream(stream);

  //     stream.getTracks().forEach((track) => {
  //       pc.addTrack(track, stream);
  //     });

  //     const answer = await pc.createAnswer();
  //     await pc.setLocalDescription(answer);

  //     socket.emit('answer', {
  //       answer: pc.localDescription,
  //       roomId,
  //     });
  //   } catch (error) {
  //     console.error('Error handling received offer:', error);
  //   }
  // };

  // const handleReceiveAnswer = async (data) => {
  //   try {
  //     if (!peerConnection) return;
  //     await peerConnection.setRemoteDescription(
  //       new RTCSessionDescription(data.answer)
  //     );
  //   } catch (error) {
  //     console.error('Error handling received answer:', error);
  //   }
  // };

  // const handleReceiveIceCandidate = (data) => {
  //   try {
  //     if (!peerConnection) return;
  //     peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
  //   } catch (error) {
  //     console.error('Error handling ICE candidate:', error);
  //   }
  // };

  const toggleChatWindow = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setNotificationCount(0); 
    }
  };
  const stopListening = (uid) => {
    const user = userStates[uid];
    if (user && user.audioTrack) {
      user.audioTrack.setEnabled(false);
    }
  };

  return (
    <>
{/*  
      {speakingUser && (
        <div className="fixed top-5 right-5 z-50 bg-gray-800 text-white p-2 rounded-lg shadow-lg">
          User {speakingUser} is speaking
        </div>
      )} */}


      <div
        className={`fixed bottom-5 right-5 z-50 cursor-pointer bg-blue-500 text-white rounded-full p-3 shadow-lg hover:bg-blue-600 transition-colors ${
          isOpen ? 'hidden' : 'block'
        }`}
        onClick={toggleChatWindow}
      >
        <FaComments className="text-2xl" />
        {notificationCount > 0 && (
          <span className="absolute top-0 right-0 flex h-4 w-4 items-center justify-center bg-red-500 text-xs text-white rounded-full">
            {notificationCount}
          </span>
        )}
      </div>


      {/* Draggable Chat Window */}
      <Draggable handle=".handle">
        <div
          className={`fixed bottom-5 right-5 z-50 w-96 transition-transform transform ${
            isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-75 hidden'
          }`}
        >
          <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-gray-200 border border-gray-700 rounded-t-lg shadow-lg overflow-hidden">
            <div className="handle relative flex items-center justify-between p-4 bg-gray-900 text-gray-200 cursor-move">
              <h3 className="font-bold text-lg">Game Chat</h3>
              <AiOutlineClose
                className="text-2xl cursor-pointer hover:text-red-500 transition-colors"
                onClick={toggleChatWindow}
              />
            </div>
            <div
              className={`chat-window flex flex-col ${
                isOpen ? 'h-96' : 'h-16'
              } transition-height duration-300`}
            >
              <div className="messages flex-1 p-4 overflow-y-auto space-y-4 bg-gray-900 scrollbar-hidden">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex items-start space-x-4 ${
                      msg.userId === userId ? 'justify-end' : 'justify-start'
                    }`}
                  >
                    {msg.userId !== userId && (
                      <img
                        src={msg.userprofileUrl}
                        alt={msg.userName}
                        className="w-10 h-10 rounded-full"
                      />
                    )}
                    <div
                      className={`${
                        msg.userId === userId
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-700 text-gray-300'
                      } rounded-lg p-2`}
                    >
                      <div className="font-semibold">{msg.userName}</div>
                      <div className="text-sm">{msg.content}</div>
                      <div className="text-xs text-gray-400">
                        {moment(msg.timestamp).format('h:mm A')}
                      </div>
                    </div>
                    {msg.userId === userId && (
                      <img
                        src={msg.userprofileUrl}
                        alt={msg.userName}
                        className="w-10 h-10 rounded-full"
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="flex items-center p-4 bg-gray-800 border-t border-gray-700">
                <button
                  onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                  className="text-2xl text-gray-400 hover:text-gray-200 transition-colors"
                >
                  <FaSmile />
                </button>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                  className="flex-1 mx-4 p-2 bg-gray-700 text-white rounded-lg outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Type a message..."
                />
                <button
                  onClick={handleSendMessage}
                  className="text-xl text-white bg-blue-600 p-2 rounded-full hover:bg-blue-700 transition-colors"
                >
                  <FaGift />
                </button>
                <button
                  onClick={
                    isVoiceChatActive ? // Mute the local audio stream
                    startVoiceChat :
                    
                    stopVoiceChat
                  }
                  className={`text-xl p-2 rounded-full transition-colors ${
                    isVoiceChatActive
                      ? 'bg-red-600 text-white hover:bg-red-700'
                      : 'bg-green-600 text-white hover:bg-green-700'
                  }`}
                >
                  {isVoiceChatActive ? <FaMicrophoneSlash /> : <FaMicrophone />}
                </button>
              </div>
              {emojiPickerOpen && (
                <div className="absolute bottom-20 right-4 bg-gray-800 border border-gray-700 rounded-lg p-2 shadow-lg space-x-2">
                  {/* Emoji Picker */}
                  {['😀', '😂', '😍', '😎', '😢', '👍'].map((emoji, index) => (
                    <button
                      key={index}
                      onClick={() => addEmoji(emoji)}
                      className="text-2xl"
                    >
                      {emoji}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          {/* Display Local and Remote Audio */}
          {localStream && (
            <audio autoPlay muted>
              <source src={URL.createObjectURL(localStream)} />
            </audio>
          )}
          {remoteStream && (
            <audio autoPlay>
              <source src={URL.createObjectURL(remoteStream)} />
            </audio>
          )}
        </div>
        {/* {Object?.keys(speakingUser)?.map(uid => (
          <div key={uid}>User {uid} is speaking</div>
        ))} */}
      </Draggable>
    </>
  );
};

export default Chat;
