import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Wallet from "./connect";
import Loader from './Loader/index';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store'

// window.store=store;
// import { Buffer } from 'buffer';
// window.Buffer = Buffer;

// import process from 'process';
// window.process = process;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Wallet>
        <Loader />
        <App />
      </Wallet>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
