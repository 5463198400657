// App.js
import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';

const ENDPOINT = 'http://localhost:4000';

function PlayGamePage() {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [player, setPlayer] = useState('X');
  const [winner, setWinner] = useState(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    setSocket(socket);

    socket.on('updateBoard', (newBoard) => {
      setBoard(newBoard);
    });

    socket.on('gameOver', (winner) => {
      setWinner(winner);
    });

    return () => socket.disconnect();
  }, []);

  const handleClick = (index) => {
    if (!board[index] && !winner) {
      const newBoard = [...board];
      newBoard[index] = player;
      setBoard(newBoard);
      socket.emit('move', { board: newBoard, player });
      setPlayer(player === 'X' ? 'O' : 'X');
    }
  };

  return (
    <div className="App">
      <h1>Tic Tac Toe</h1>
      <div className="board">
        {board.map((cell, index) => (
          <div key={index} className="cell" onClick={() => handleClick(index)}>
            {cell}
          </div>
        ))}
      </div>
      {winner && <p>Winner: {winner}</p>}
    </div>
  );
}

export default PlayGamePage;
