import React from 'react'
import Card1 from "../../../../../../assets/img/crd1.png"

const CardHeart = ({ rank, className, playerCard }) => {
  return (
    <>
    <div className={`pvp05 ${className && className}`}>
    <span className='z-10'>{rank}</span>
    <img className={`${playerCard ? 'w-20 h-28' : ' h-32 w-24'} fade-in-up`} src={Card1} alt="card" /></div>
    </>
  )
}

export default CardHeart