// src/App.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import GameModal from './GameModal';
import {
  holdEmPokerData,
  fiveCardPokerData,
  sevenStudData,
  unoData,
  stonePaperScissorsData,
  hiLoData,
  seven27Data
} from './gameData';
import CasinoIcon from '@mui/icons-material/Casino';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';

const App = () => {
  const [modalData, setModalData] = useState({ open: false, title: '', data: [] });

  const openModal = (title, data) => {
    setModalData({ open: true, title, data });
  };

  const closeModal = () => {
    setModalData({ ...modalData, open: false });
  };

  return (
    <div className="h-full w-full  text-white ">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-4xl font-bold border-b-4 border-yellow-500 pb-2 mb-8 text-center">
          Leaderboard
        </h2>
        <div className="App h-full w-full flex flex-col items-center justify-center p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-4xl">
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('Hold\'em Poker', holdEmPokerData)}
              startIcon={<CasinoIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              Hold'em Poker
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('5 Card Poker', fiveCardPokerData)}
              startIcon={<CardGiftcardIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              5 Card Poker
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('7 Stud Poker', sevenStudData)}
              startIcon={<SportsScoreIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              7 Stud Poker
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('Uno', unoData)}
              startIcon={<PlayArrowIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              Uno
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('Stone Paper Scissors', stonePaperScissorsData)}
              startIcon={<SportsEsportsIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              Rock Paper Scissors
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('Hi Lo', hiLoData)}
              startIcon={<WhatshotIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              Hi Lo
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => openModal('7/27 Game', seven27Data)}
              startIcon={<LocalPlayIcon />}
              className="flex items-center justify-center shadow-lg hover:bg-yellow-600 transition-all duration-200"
            >
              7/27 Game
            </Button>
          </div>

          <GameModal
            open={modalData.open}
            onClose={closeModal}
            title={modalData.title}
            data={modalData.data}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
