import React, { useEffect, useState } from 'react'
import PotMoney from './PotMoney';
import Player from './Player';
import MenuActions from './MenuActions';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../../../../Config/function';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';

import coins from '../../../../../assets/img/games/coins_transparent.png';
import blankCard from '../../../../../assets/img/games/blankcard.png';
import HighLowActionbuttons from './HighLowActionbuttons';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../../CommonComponent/Toast';
import axiosInstance from '../../../../../Config/axios';
// import BettingCard from './BettingCard';
import HiddenCard from './HiddenCard';
import hotToast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import BettingCard from "./Cards/Common/Card"
import Chat from '../../../../ChatingComponent/chatingComponent';

const PlayGame = ({ gameInfo, socket }) => {
    gsap.registerPlugin(Flip);
    const userData = useSelector(state => state.auth.userData)
    const players = gameInfo?.players || []
    const myProfile = gameInfo?.players.find(player => player.userId === userData?.id)
    const isInGame = !isEmpty(myProfile)
    const navigate = useNavigate()
    const { gameAddress } = useParams()


    const [gameBetStatus, setGameBetStatus] = useState({
        buttons: [],
        currentPlayer: 0,
        betAmount: 0,
        isStarted: false,
        pot: 0,
        userBalances: [],
        foldedPlayers: [],
        timer: 0,
    })
    const [gameStatus, setGameStatus] = useState(gameInfo?.round || 'starting') // 'starting', 'betting', 'playing
    const [showResultCard, setShowResultCard] = useState(null)
    const [currentMoveInfo, setCurrentMoveInfo] = useState({
        card: {},
        currentPlayer: 0,
        pot: 0,
        userBalances: [],
        maxBet: 0,
        foldedPlayers: [],
        leftPlayers: [],
        timer: 0,
    })

    const fetchPlayerBetAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/bet-status', { gameAddress: gameInfo.gameAddress })

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                if (responseData.data?.isStarted) {
                    // setGameStatus('playing')
                }
                setGameBetStatus(responseData.data)
            }

        } catch (error) {
            toast.error(<ToastContent title="Error" message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
            })
        }
    }

    const fetchPlayetMoveAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/move-status', { gameAddress: gameInfo.gameAddress })

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                setCurrentMoveInfo(responseData.data)
            }
        } catch (error) {
            toast.error(<ToastContent title="Error" message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
            })
        }
    }

    const animateFlowToPot = (playerId, targetId, isCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null
        if (isCard) {
            card = document.createElement('img');
            card.className = 'card h-16 w-16 bg-transparent absolute rounded-full rounded-md';
            card.src = blankCard;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = ' h-12 w-12 bg-transparent absolute ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.body.appendChild(card);

        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const flipElement = (id) => {
        const element = document.getElementById(id);
        const flipState = Flip.getState(element);

        // Toggle the flipped class
        if (!element) return;
        element.classList.toggle('flipped');

        Flip.from(flipState, {
            duration: 1,
            ease: 'power1.inOut',
            onEnter: (elements) => gsap.fromTo(elements, { rotationY: -180 }, { rotationY: 0, duration: 1 }),
            onLeave: (elements) => gsap.fromTo(elements, { rotationY: 0 }, { rotationY: 180, duration: 1 }),
        });

        setTimeout(() => {
            if (!element) return;
            element.classList.toggle('flipped');
        }, 1000)
    };

    useEffect(() => {
        if (!socket) return;
        socket.on('betPlayed', (data) => {
            animateFlowToPot(`hilo_player_${data.userId}`, 'pot_money_box');
            if (data?.betFinished) {
                hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                setGameStatus('playing');
                fetchPlayetMoveAction();
            } else if (data.gameStatus == 'betting') {
                if (data.isStarted) {
                    hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                } else {
                    setGameStatus(data.gameStatus);
                    fetchPlayerBetAction();
                }
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        })

        socket.on('movePlayed', (data) => {
            const { userId, action, amount, isWin, isDraw, isFinished, nextCard } = data;
            if (action === "Timeout") {
                hotToast.error('Timeout', { duration: 2000, position: 'bottom-center' })
                if (isFinished) {
                    hotToast.success('Round Over !! Now play your bet', { duration: 5000, position: 'bottom-center' })
                    setGameStatus('betting')
                    fetchPlayerBetAction();
                } else {
                    fetchPlayetMoveAction();
                }
                return;
            }

            let targetId = ``;
            let sourceId = ``;
            if (isWin) {
                targetId = `hilo_player_${userId}`;
                sourceId = `pot_money_box`;
            } else if (!isDraw) {
                targetId = `pot_money_box`;
                sourceId = `hilo_player_${userId}`;
            } else {
                hotToast.success('Draw', { duration: 1000, position: 'bottom-center' })
            }
            animateFlowToPot(`high_low_hidden_card`, `high_low_betting_card`, true);

            setTimeout(() => {
                setTimeout(() => {
                    if (isWin) {
                        if (userId === userData?.id) {
                            hotToast.success('You won', { duration: 3000, position: 'bottom-center' })
                        } else {
                            hotToast.success('Opponent won', { duration: 3000, position: 'bottom-center' })
                        }
                    } else if (!isDraw) {
                        if (userId === userData?.id) {
                            hotToast.success('You Lost', { duration: 1000, position: 'bottom-center' })
                        } else {
                            hotToast.success('Opponent Lost', { duration: 1000, position: 'bottom-center' })
                        }
                    } else {
                        hotToast.success('Draw', { duration: 1000, position: 'bottom-center' })
                    }
                    if (isFinished) {
                        setShowResultCard(nextCard);

                        setTimeout(() => {
                        animateFlowToPot(sourceId, targetId, false);
                            setShowResultCard(null);
                            flipElement('high_low_hidden_card');
                            setTimeout(() => {
                                hotToast.success('Round Over !! Now play your bet', { duration: 2000, position: 'bottom-center' })
                                setGameStatus('betting')
                            }, 3000);
                        }, 3000);


                    } else {
                        setShowResultCard(nextCard);
                        setTimeout(() => {
                        animateFlowToPot(sourceId, targetId, false);
                            setShowResultCard(null);
                            flipElement('high_low_hidden_card');
                            fetchPlayetMoveAction();
                        }, 3000);

                    }
                }, 1000)
            }, 1000)
        })


        socket.on('opponentLeft', (data) => {
            if (gameStatus == 'betting') {
                fetchPlayerBetAction();
            } else if (gameStatus == 'playing') {
                fetchPlayetMoveAction();
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        })


        return () => {
            socket.off('betPlayed');
            socket.off('movePlayed');
        }
    }, [gameInfo])


    useEffect(() => {
        if (gameStatus === 'starting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'betting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'playing') {
            fetchPlayetMoveAction();
        }
    }, [gameStatus])

    let allPlayers = [...players];
    const restOtherPlayers = players.filter(player => player.userId !== userData?.id)
    if (myProfile) {
        allPlayers = [myProfile, ...restOtherPlayers]
    }

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allPlayers.length == 1) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }

        if (allPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (allPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (allPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/4 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-1/4 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (allPlayers.length == 8) { }

        return className
    }

    let userBalances = gameBetStatus?.userBalances || []
    let pot = gameBetStatus?.pot || 0
    let maxBet = gameBetStatus?.maxBet || 0
    let foldedPlayers = gameBetStatus?.foldedPlayers || []
    let leftPlayers = gameBetStatus?.leftPlayers || []
    let currentPlayer = gameBetStatus?.currentPlayer || 0
    let timer = gameBetStatus?.timer || 0
    if (gameStatus === 'playing') {
        maxBet = currentMoveInfo?.maxBet || 0
        pot = currentMoveInfo?.pot || 0
        userBalances = currentMoveInfo?.userBalances || []
        foldedPlayers = currentMoveInfo?.foldedPlayers || []
        leftPlayers = currentMoveInfo?.leftPlayers || []
        currentPlayer = currentMoveInfo?.currentPlayer || 0
        timer = currentMoveInfo?.timer || 0
    }



    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('hilo_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }

    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/leave', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    return (
        <div id='hilo_screen' className='game flex flex-col items-center h-full'>
            <Chat userId={userData?.id} roomId={gameAddress} />
            <MenuActions gameStatus={gameInfo?.status} enableFullScreen={enableFullScreen} isInGame={isInGame} handleLeaveGame={handleLeaveGame} />
            <div className='relative w-full h-[55%] p-3 bg-gray-900 border-2  mt-10 container rounded-2xl '>
                <div className=" rounded-lg w-full h-full  bg-green-900 border-2 container">
                    {allPlayers.map((player, index) => {
                        return (
                            <div key={index} className={getClassName(index)}>
                                <Player showResultCard={showResultCard} gameStatus={gameStatus} socket={socket} currentPlayer={currentPlayer} leftPlayers={leftPlayers} foldedPlayers={foldedPlayers} userBalances={userBalances} player={player} timer={timer} />
                            </div>
                        );
                    })}
                    <div className="absolute left-1/2 w-100  right-1/2 top-1/2  transform -translate-x-1/2 -translate-y-1/2 ">
                        <div className='relative w-50 mx-auto flex items-center justify-center '>
                            {gameStatus == 'playing' && <div className='betting_card absolute left-0 top-1/2 transform -translate-y-1/2'>
                                <BettingCard card={currentMoveInfo?.card} />
                            </div>}
                            <div id='pot_money_box'  className=' mx-4 text-sm absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                                <PotMoney pot={pot} />
                            </div>

                            {(gameStatus == 'playing') && <div className={`hidden_card absolute right-0 top-1/2 transform z-10 -translate-y-1/2`}>
                                <HiddenCard />
                            </div>}
                            {showResultCard && (gameStatus == 'playing') && <div className='fade-in-up z-20 absolute right-0 top-1/2 transform -translate-y-1/2'>
                                <BettingCard card={showResultCard} />
                            </div>}
                        </div>

                    </div>

                </div>

            </div>
            <div className='w-full h-auto p-3  mt-5 container '>
                <HighLowActionbuttons maxBet={maxBet} pot={pot} gameStatus={gameStatus} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} />
            </div>
        </div>
    )
}

export default PlayGame
