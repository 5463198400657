import React, { useEffect, useState } from 'react'
import BetButtons from './BetButtons';
import PlayButtons from './PlayButtons';
import { useSelector } from 'react-redux';
const HighLowActionbuttons = ({ maxBet, pot, gameStatus, gameBetStatus, currentMoveInfo }) => {

    const [isSelected, setIsSelected] = useState(false)

    const handleSelected = (status) => {
        if (status) return setIsSelected(true)
        setIsSelected(false)
    }

    const userData = useSelector(state => state.auth.userData)



    let currentPlayer = gameBetStatus?.currentPlayer;

    if (gameStatus == 'playing') {
        currentPlayer = currentMoveInfo?.currentPlayer;
    }

    useEffect(() => {
        if (currentMoveInfo?.currentPlayer == userData?.id || gameBetStatus?.currentPlayer == userData?.id) {
            setIsSelected(false)
        }
    }, [gameBetStatus, currentMoveInfo])
    return (
        <div className='highlow_action_buttons flex flex-col items-center justify-between w-full'>
            {(gameStatus == 'betting') && (gameBetStatus?.currentPlayer == userData?.id) && <BetButtons gameBetStatus={gameBetStatus} />}
            {(gameStatus == 'playing') && (currentMoveInfo?.currentPlayer == userData?.id) && <PlayButtons isSelected={isSelected} handleSelected={handleSelected} pot={pot} maxBet={maxBet} currentMoveInfo={currentMoveInfo} />}
        </div>
    )
}

export default HighLowActionbuttons
