// src/Leaderboard.js
import React from 'react';
import { FaTrophy } from 'react-icons/fa';
import { BiMedal } from 'react-icons/bi';
import { GiGoldBar } from 'react-icons/gi';

const Leaderboard = ({ title, data }) => {
  return (
    <div className="bg-gray-800 text-white py-8 px-4 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-6">{title}</h2>
      <ul className="space-y-4">
        {data.map((player) => (
          <li key={player.position} className="flex items-center p-4 bg-gray-700 rounded-lg shadow-md hover:bg-gray-600 transition duration-300">
            {player.position === 1 && <FaTrophy className="text-yellow-500 text-3xl mr-4" />}
            {player.position === 2 && <BiMedal className="text-gray-400 text-3xl mr-4" />}
            {player.position === 3 && <GiGoldBar className="text-yellow-300 text-3xl mr-4" />}
            <span className="w-12 text-center text-2xl font-bold">{player.position}</span>
            <span className="flex-1 text-lg font-semibold">{player.name}</span>
            <span className="w-28 text-right text-lg font-semibold">{player.score}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;
