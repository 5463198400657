import React from 'react';
import './Loader.css'; // Ensure this file contains the CSS styles for the loader

const Loader = ({ loading }) => {
  return (
    loading && (
      <div id="overlay">
        <div className="cv-spinner">
          <span className="spinner"></span>
        </div>
      </div>
    )
  );
};

export default Loader;
