import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import hotToast from 'react-hot-toast';
import LeaveSevenTwentySevenModal from '../../../../../CommonComponent/LeaveSevenTwentySevenModal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const MenuActions = ({ isInGame, handleJoinGame, enableFullScreen, gameStatus, handleLeaveGame, gameInfo }) => {
  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const { gameAddress } = useParams()
  const navigate = useNavigate()

  const handleToggleLeaveModal = () => {
    setShowLeaveModal(!showLeaveModal)
  }

  let message = 'Are you sure you want to leave the game?'

  if (gameInfo.status === 'pending') {
    message = 'Are you sure you want to cancel the game?'
  } else if (gameInfo.status === 'started') {
    message = 'Are you sure you want to leave the game? You will lose the game if you leave now.'
  } else if (gameInfo.status === 'completed') {
    message = 'Are you sure you want to leave the game?'
  }

  const handleCopy = () => {
    hotToast.success('Address copied to clipboard', { position: "bottom-center", autoClose: 2000, transition: "slide" })
  } 
  const handleBack = () => {
    navigate('/all-game-page')
}

  return (

    <>
      <div className='flex container flex-row items-center justify-between w-full p-2'>
        <div className=''>
          {((gameInfo.status === 'pending') || gameInfo.status === 'completed') && !isInGame &&
            <button onClick={() => handleBack()} className='bg-transparent mr-2 text-white btn text-center py-2.5 text-xs'>
              <KeyboardBackspaceIcon className='mr-2' fontSize='14' /> Go Back
            </button>}
          {gameInfo.status !== 'started' && <CopyToClipboard text={gameAddress} onCopy={handleCopy}>
            <button className='bg-blue-800 mr-2 text-white btn text-center text-xs'>
              <ContentCopyIcon className='mr-2' fontSize='18' /> Copy Address
            </button>
          </CopyToClipboard>}
        </div>
        <div className='mr-2'>
          {gameInfo.status !== 'started' && !(isInGame) && (gameInfo.status !== 'completed') && <button onClick={handleJoinGame} className=' bg-blue-800 mr-2 text-xs text-white btn text-center '>
            Join Game
          </button>}
          {isInGame && <button onClick={handleToggleLeaveModal} className=' bg-red-800 mr-2 text-sm text-white btn text-center '>
            <CloseIcon fontSize='18' />
          </button>}
          <button className='bg-green-600 mr-2 font-bold inset-2 shadow text-white btn text-sm text-center'>
            <VolumeUpIcon fontSize='18' />
          </button>
          <button onClick={enableFullScreen} className='bg-green-600  font-bold inset-2 shadow text-white btn text-center'>
            <FitScreenIcon fontSize={'18'} />
          </button>
        </div>
      </div>
      {showLeaveModal && <LeaveSevenTwentySevenModal show={showLeaveModal} handleClose={handleToggleLeaveModal} handleSubmit={handleLeaveGame} text={message} />}
    </>
  )
}

export default MenuActions
