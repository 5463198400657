import React from 'react'

const LeaveFiveCardPokerModal = ({ show, handleClose, handleSubmit, text }) => {

    if (!show) return null;


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-95">
            <div className="text-white rounded-lg pb-2 pt-4 px-3 w-full max-w-md relative bg-dark">
                <button
                    type="button"
                    className="absolute -top-4 -right-3 px-3 py-1 bg-gray-300 text-gray-700 rounded-3xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                    onClick={handleClose}
                >
                    X
                </button>

                <h2 className="text-2xl font-bold text-center mb-4">Leave Game</h2>
                <p className="text-center">{text}</p>


                <div className="flex justify-center mt-4 mb-3">
                    <button
                        type="button"
                        className="bg-red-800 text-white px-4 py-2 rounded-lg mr-2"
                        onClick={handleSubmit}
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        className="bg-green-800 text-white px-4 py-2 rounded-lg"
                        onClick={handleClose}
                    >
                        No
                    </button>
                    </div>
               
            </div>
        </div>
    )
}

export default LeaveFiveCardPokerModal
