import React from 'react'

const Loading = () => {
    return (
        <div>
            <div className='flex flex-col justify-center items-center h-screen'>
                <div className="flex items-center justify-center">
                    <div className="w-10 h-10 bg-white rounded-full mr-2 animate-ping"></div>
                    <div className="w-10 h-10 bg-white rounded-full mr-2 animate-ping delay-100"></div>
                    <div className="w-10 h-10 bg-white rounded-full mr-2 animate-ping delay-200"></div>
                    <div className="w-10 h-10 bg-white rounded-full mr-2 animate-ping delay-300"></div>
                    <div className="w-10 h-10 bg-white rounded-full mr-2 animate-ping delay-400"></div>
                </div>
                <h1 className='text-white text-5xl font-extrabold mt-5 ml-2'>Loading...</h1>
            </div>
        </div>
    )
}

export default Loading
