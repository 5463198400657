// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../Config/axios'

// ** UseJWT import to get config


const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const convertToUSD = createAsyncThunk("auth/convertToUSD", async (data) => {
  const response = await axiosInstance.post('/common/currecy-exchange', data)
  return response.data
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    solPrice: 0,
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
    },
    handleLogout: state => {
      state.userData = {}
    },

  },
  extraReducers: (builder) => {
    builder.addCase(convertToUSD.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.solPrice = action.payload.data.amount
      }
    })
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
