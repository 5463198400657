import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import { useSelector } from 'react-redux'

const Player = ({ player }) => {
    const userData = useSelector(state => state.auth.userData)

    return (
        <div className='hi-lo-player flex items-center flex-col	'>
            {<img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${player.active && "active"}`} />}
            <div className='flex flex-col items-center justify-center bg-black font-extrabold px-3 text-center border-2 rounded-md'>
                <div className='name text-white mb-1 text-xs'>{
                    player.userName ? player.userName :
                        formatAddress(player.address, 3)
                }</div>
                {player?.userId === userData?.id ? <div className='balance text-white text-xs'>{Number(player.amount).toFixed(2)} Sol</div> : <div className='balance text-white  text-xs'> *** Sol</div>}
            </div>
        </div>
    )
}

export default Player
