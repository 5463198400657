// src/PokerTournamentPage.js
import React, { useState } from 'react';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Sample tournament data
const tournaments = [
  { id: 1, name: "High Stakes Tournament", date: "2024-08-15", prizePool: "$50,000" },
  { id: 2, name: "Weekend Poker Championship", date: "2024-08-22", prizePool: "$30,000" },
  { id: 3, name: "Monthly Poker Showdown", date: "2024-09-05", prizePool: "$10,000" },
];

const TournamentDetails = ({ open, onClose, tournament }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
    PaperProps={{
      style: {
        backgroundColor: '#1e293b', // Tailwind's blue-gray-800
        color: 'white',
        borderRadius: '8px', // Slightly rounded corners
      },
    }}
  >
    <DialogTitle
      sx={{ backgroundColor: '#0f172a', color: 'white' }} // Tailwind's blue-gray-900
    >
      <div className="flex justify-between items-center">
        <span className="text-xl font-bold">Tournament Details</span>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent
      sx={{ backgroundColor: '#1e293b', padding: '24px' }}
    >
      {tournament && (
        <div>
          <h3 className="text-lg font-semibold mb-2">{tournament.name}</h3>
          <p className="mb-2">Date: <span className="font-medium">{tournament.date}</span></p>
          <p className="mb-2">Prize Pool: <span className="font-medium">{tournament.prizePool}</span></p>
          <p>Description: This is a detailed description of the tournament...</p>
        </div>
      )}
    </DialogContent>
  </Dialog>
);

const PokerTournamentPage = () => {
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (tournament) => {
    setSelectedTournament(tournament);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTournament(null);
  };

  return (
    <div className="h-full w-full  p-6 ">
      <div className="max-w-6xl mx-auto px-4 py-8">
        <h2 className="text-4xl font-bold text-white text-center mb-8 border-b-4 border-yellow-500 pb-2">
          Poker Tournaments
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {tournaments.map(tournament => (
            <Card key={tournament.id} sx={{ backgroundColor: '#1e293b', color: 'white', borderRadius: '8px' }}>
              <CardHeader
                title={<span className="font-bold">{tournament.name}</span>}
                subheader={<span className="text-gray-400">Date: {tournament.date}</span>}
                sx={{ borderBottom: '1px solid #2d3748' }} // Tailwind's blue-gray-800
              />
              <CardContent>
                <p className="text-gray-300 mb-4">Prize Pool: <span className="font-medium">{tournament.prizePool}</span></p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModal(tournament)}
                  sx={{ backgroundColor: '#f59e0b', color: '#1e293b', '&:hover': { backgroundColor: '#d97706' } }} // Tailwind's amber-500 and amber-600 for hover
                  className="w-full py-2 transition-all duration-200"
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      <TournamentDetails
        open={modalOpen}
        onClose={handleCloseModal}
        tournament={selectedTournament}
      />
    </div>
  );
};

export default PokerTournamentPage;
