import React from 'react'
import { useNavigate } from 'react-router-dom'
import QuitLobby from '../../PokerTest/Components/Pages/QuitLobby'
import Dealer from '../../PokerTest/Components/Pages/Dealer'
import CommunityCards from '../../PokerTest/Components/Pages/Dealer/CommunityCard'
import Player from './Player'
import { useSelector } from 'react-redux'

const GameResult = ({ handlePlayAgain, gameInfo }) => {


  const solPrice = useSelector(state => state.auth.solPrice)
  const winnerAddress = gameInfo?.gameResult?.winner
  const userData = JSON.parse(localStorage.getItem('userData'))

  const isWinner = winnerAddress === userData?.address
  const navigate = useNavigate()


  let allCommunityCards = []

  const { servedCards } = gameInfo

  if (servedCards) {
    allCommunityCards = [...servedCards['CommunityCards'], ...servedCards['CommunityCards1'], ...servedCards['CommunityCards2']]
  }

  let gameResult = gameInfo?.gameResult

  let gamePlayers = gameResult?.players || []


  const playerCards = gamePlayers.map(player => {
    let user = servedCards['PlayerCards'].filter(item => item['Name'] === player?.address)
    user = user[0];
    return { ...player, 'Hand': user['Hand'] }
  })



  const myProfile = playerCards.filter(player => player.address === userData?.address)

  const otherPlayers = playerCards.filter(player => player.address !== userData?.address)

  let allPlayers = playerCards
  if (myProfile) {
    allPlayers = [...myProfile, ...otherPlayers]
  }



  const getClassName = (index) => {
    let className = 'absolute flex flex-col items-center top-5 left-5'

    // always show the user at the bottom and the rest of the players in a circular manner

    if (allPlayers.length == 2) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2'
      }
    } else if (allPlayers.length == 3) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 2) {
        return 'absolute flex flex-col items-center right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
      }
    } else if (allPlayers.length == 4) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2'
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
      }
    } else if (allPlayers.length == 5) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    } else if (allPlayers.length == 6) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
      if (index == 5) {
        return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    } else if (allPlayers.length == 7) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 '
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
      if (index == 5) {
        return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
      }
      if (index == 6) {
        return 'absolute flex flex-col items-center bottom-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    } else if (allPlayers.length == 8) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 '
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center -top-10 left-1/2 transform -translate-x-1/2'
      }
      if (index == 5) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
      if (index == 6) {
        return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
      }
      if (index == 7) {
        return 'absolute flex flex-col items-center bottom-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    }




    return className
  }


  const handleQuitLobby = () => {
    navigate('/all-game-page');
  }

  const RenderUsd = ({ amount }) => {
    if (!solPrice) return null
    if (!amount) return null
    let newAmount = Number(amount)

    return <span className='text-sm text-white font-bold ms-2'>({(solPrice * newAmount).toFixed(2)} USD)</span>
  }



  return (
    <div className=' z-10 absolute top-0 left-0 h-screen w-full '>
      <div className='relative rounded-lg px-5 py-2 block mx-auto h-screen container'>
        <QuitLobby message="If you quit, you will be out of the table" handleQuitLobby={handleQuitLobby} />

        <div className="w-full mt-5  bg-green-900 border-2 rounded-full p-4 flex justify-center items-center  h-[65%]">
          <div className='relative w-full h-full border-2  bg-gradient-to-r from-emerald-800 to-emerald-900 rounded-full'>
            <div className='absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4'>
              <h2 className='text-white font-semibold'>
                {isWinner ?
                  <span className='text-white text-2xl flex flex-col justify-center items-center'>
                    <span className='text-green-500'>You won</span><br />
                    <div className='text-white mx-auto w-full text-center'>{Number(gameInfo.pot).toFixed(2)} Sol
                      <RenderUsd amount={gameInfo.pot} />
                    </div>
                  </span>
                  : <span className='text-white text-2xl'>You lost</span>
                }
              </h2>

            </div>
            <div className='absolute w-1/2 block mx-auto top-1/2 left-0 transform translate-x-1/2 -translate-y-1/4'>
              <CommunityCards showResult={true} card={allCommunityCards} gameResult={gameInfo?.gameResult} />
            </div>

            {allPlayers.map((player, index) => {
              return (
                <div id={`player_${player?.userId}`} className={`${getClassName(index, player.userId)}`} >
                  <Player key={index} player={player} gameResult={gameInfo?.gameResult} />
                </div>
              )
            })}

          </div>
        </div>
      </div>




      <button onClick={handlePlayAgain} role='button' className='absolute bottom-10 left-1/2 transform -translate-x-1/2 mx-auto w-1/2 bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'>
        Press here to play/continue...
      </button>
    </div>
  )
}

export default GameResult
