import React from 'react'
import { useSelector } from 'react-redux'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import hotToast from 'react-hot-toast'
import axiosInstance from '../../../../../Config/axios'
import { toast, Slide } from 'react-toastify'
import ToastContent from '../../../../../CommonComponent/Toast'
import { formatAddress } from '../../../../../Config/function'
import SolToUSD from '../../../../../CommonComponent/SolToUSD'

const GameResult = ({ gameInfo, socket, fetchGameInfo }) => {
    const userData = useSelector(state => state.auth.userData)

    const { gameResult } = gameInfo

    let players = []
    let pot = 0
    let winner = null
    if (gameResult) {
        const resultData = (gameResult)
        players = resultData.players
        pot = resultData.pot
        winner = resultData.winner
    }


    let allPlayers = players || []

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (allPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/2'

        } else if (allPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
            if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
        } else if (allPlayers.length == 8) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

        } else if (allPlayers.length == 9) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
        }
        return className
    }
    const handleJoinGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo.gameAddress }
            const response = await axiosInstance.post('/api/game/uno-card-game/join', bodyData)

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                const data = { slug: 'uno-card-game', gameAddress: gameInfo.gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
                hotToast.success('Joined game successfully', { duration: 2000, position: 'bottom-center' })
                if (socket) {
                    socket.emit('joinedGame', { userId: userData?.id, roomId: gameInfo.gameAddress })
                }
            }

        } catch (error) {
            toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, transition: Slide })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('seven_tws_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }

    const RenderGameWinner = () => {
        if (!winner) return null;

        // const { profileUrl, address } = winner
        return (
            <div className='flex flex-row items-center justify-between w-full container gap-11'>
                <div className='flex flex-col items-center justify-center'>
                    <span className='text-white font-bold text-2xl mt-2 mb-2'>Winner</span>
                    <div className='hi-lo-player flex items-center flex-col	'>
                        {<img src={winner.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-12 w-12 border border-white p-1/2 hi_lo_player__profile_active`} />}
                        <span className='hi-lo-player__balance text-white font-bold mt-3'>{winner.userName ? winner.userName : formatAddress(winner.address, 6)}</span>
                        <div className='flex items-center justify-center flex-col text-white'>
                            <span className='text-sm'>{Number(winner.amount).toFixed(2)} Sol</span>
                            <span className='text-xs'>
                                (<SolToUSD amount={winner.amount} symbol={'USD'} />)
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        )

    }


    return (
        <div id='seven_tws_screen' className='relative game  flex flex-col items-center h-full'>
            <MenuActions isInGame={false} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
            <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-20 container rounded-full '>
                <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

                    <div className="  w-full h-full rounded-full border-2" style={{
                        backgroundColor: '#222222'
                    }}>
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <RenderGameWinner />
                        </div>
                        {allPlayers.map((player, index) => {
                            return (
                                <div key={index} className={getClassName(index)}>
                                    <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                        <Player isWaiting={true} player={player} />
                                    </div>
                                </div>
                            );

                        })}
                    </div>
                </div>
            </div>
            <div className='absolute bottom-5 text-white left-1/2 transform -translate-x-1/2'>
                <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'> Press here to play/continue... </button>
            </div>
        </div>
    )
}

export default GameResult
