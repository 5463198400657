import React from 'react'
import { useParams } from 'react-router-dom'
import RockPaperScissor  from './Rock_Paper_Scissors'
import Poker from './Poker'
import HiLoCardGame from './HiLoCardGame'
import SevenTwentySevenPoker from './SevenTwentySevenPoker'
import FiveCardDrawPoker from './FiveCardPoker'
import SevenCardStudPoker from './SevenCardPoker'
import UnoCardGame from './UnoCardGame'

const GamePlay = () => {

  const { gameAddress, slug } = useParams()



  if (slug === 'rock-paper-scissors') {
    return <RockPaperScissor gameAddress={gameAddress} />
  } else if (slug === 'poker') {
    return <Poker gameAddress={gameAddress} />
  } else if (slug === 'hi-lo-card-game') {
    return <HiLoCardGame gameAddress={gameAddress} />
  } else if (slug === '7-27-poker') {
    return <SevenTwentySevenPoker gameAddress={gameAddress} />
  } else if (slug === '5-card-poker') {
    return <FiveCardDrawPoker gameAddress={gameAddress} />
  } else if (slug === '7-card-poker') {
    return <SevenCardStudPoker gameAddress={gameAddress} />
  } else if (slug === 'uno-card-game') {
    return <UnoCardGame gameAddress={gameAddress} />
  }
}

export default GamePlay