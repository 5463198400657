import React from 'react'

import HeartCard from './HeartCard'
import SpadeCard from './SpadeCard'
import DiamondCard from './DiamondCard'
import ClubCard from './ClubCard'

const Card = ( { card, className }) => {

    const Rank = card['Rank']
    const Suit = card['Suit']


  return (
    <div className={`${ className ? className : "w-20 h-28  rounded-md"} bg-white`}>
     {Suit == 'H' && <HeartCard Rank={Rank} />}
     {Suit == 'S' && <SpadeCard Rank={Rank} />}
     {Suit == 'D' && <DiamondCard Rank={Rank} />}
     {Suit == 'C' && <ClubCard Rank={Rank} />}

    </div>
  )
}

export default Card
