"use Server";
import React, { useState, createContext, useEffect } from "react";
import { useContractRead, useContractWrite, useAccount, useSignMessage } from "wagmi";
import { toast } from "react-toastify";
import {
  StakingAbi1,
  stakingAddress1,
  StakingAbi2,
  stakingAddress2,
  approveAbi,
  approvetoken,
} from "./constant";
import axiosInstance from "../../../Config/axios";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { useDispatch,useSelector } from "react-redux";
import { fetchBalance } from "../../../redux/balanceSlice";
import { useSearchParams } from 'react-router-dom';
import base58 from "bs58";
import {fetchUserDate} from '../../../redux/userProfile'

export const StakingApp = createContext();

export const Staking = ({ children }) => {
    const [searchParams] = useSearchParams();
  const { address, isConnected } = useAccount();
  const { wallet, connect, connecting, connected, select, publicKey, signMessage: ggg } = useWallet();
  const { data: signMessageData, isLoading, signMessage } = useSignMessage();
  const nevigate = useNavigate();

  const [isLogin, setIslogin] = useState(false);
  const dispatch = useDispatch();

  // Staking 1 state
  const [amount1, setAmount1] = useState();
  const [lockTime, setLockTime] = useState();
  const [lockingTime, setLockingTime] = useState();
  const [lockdays, setLockDays] = useState();
  const [entarlock, setEntarlock] = useState("");
  const [AllowanceAmunt1, setAllowanceAmunt1] = useState();
  const [wallets, setWallets] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [signedMessage, setSignedMessage] = useState(null);


  // Staking 2 state
  const [amount2, setAmount2] = useState();
  const [lockTime2, setLockTime2] = useState();
  const [lockingTime2, setLockingTime2] = useState();
  const [lockdays2, setLockDays2] = useState();
  const [entarlock2, setEntarlock2] = useState("");
  const [AllowanceAmunt2, setAllowanceAmunt2] = useState();

  // Authentication state
  const [openTab, setOpentab] = useState(false);
  const [openAuth, setAuth] = useState(false);
  const [user, setUser] = useState('');
  const [asset, setAsset] = useState();

  useEffect(() => {
    if (connected) {
      setWallets(1);
      setOpen1(true)
    }
  }, [!connected])

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);



  useEffect(() => {
    updateProfile();
  }, []);
  
  function updateProfile(){
    dispatch(fetchUserDate());
  }

         

  const { data, status, error } = useSelector((state) => state.userData);



  const [nonces, setNonce] = useState('');

  const SignIn = async () => {
    try {
      const res = await axiosInstance.get("/auth/generate-nonce");
      if (!connected) {
        handleSignMessageEVM(res.data.data.nonce);
      }
      else {
        handleSignMessage(res.data.data.nonce);
      }
      setNonce(res.data.data.nonce);
    } catch (err) {
      console.log(err);
    }
  };


  const handleSignMessage = async (data) => {

    console.log("enter solana", data)
    if (!publicKey || !signMessage) {
      alert('Wallet not connected or does not support message signing');
      return;
    }
    const message = data;
    const encodedMessage = new TextEncoder().encode(message);

    try {
      const signature = await ggg(encodedMessage, "utf8");
      const signatureArray1 = Array.from(signature.slice(0, 64));
      const signatureString1 = signatureArray1.join(', ');
      setSignedMessage(base58.encode(signature));
      console.log('Signed Message:', signature);
    } catch (error) {
      console.error('Error signing message:', error);
    }

  };

  const handleSignMessageEVM = async (nonce) => {
    console.log('enter', nonce);
    if (isConnected) {
      try {
        const result = await signMessage({
          message: nonce,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("wallet not connected");
    }
  };

  useEffect(() => {
    if (signMessageData) {

      Auth('ethereum', nonces, signMessageData, address);
    }
  }, [signMessageData])


  useEffect(() => {
    if (signedMessage) {

      Auth('solana', nonces, signedMessage, publicKey.toBase58());
    }
  }, [signedMessage])

  useEffect(() => {
    const token = Cookies.get('token')
    if ((isConnected || connected) && token == null)
      SignIn();
  }, [isConnected || connected])


  const ref = searchParams.get('ref');
  const Auth = async (walletType, nonce, signedMessage, publicKey) => {
    const ref = searchParams.get('ref');
    axiosInstance
      .post("/auth/login", { message: nonce, signature: signedMessage, address: publicKey, walletType: walletType, referralcode:ref })
      .then((res) => {
        if (res.data.status) {
          const expirationTime = 1;
          Cookies.set('token', res.data.data.token, { expires: expirationTime });
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          setOpentab(true);
          setAuth(true);

          window.location.replace('/all-game-page')
        }
        else {
          setOpentab(true);
          setAuth(false);
        }
      })
      .catch((err) => {
      });
  };

  const verify = async () => {
    axiosInstance
      .get("/auth/refresh-token")
      .then((res) => {

        if (res.data.code === 200) {
          setUser(res.data.data);
          setAuth(true)
        }
        else {
          nevigate("/");
        }
      })
      .catch((err) => {
        nevigate("/")
      });
  };

  const getAsset = async () => {
    axiosInstance
      .get("getasset")
      .then((res) => {
        if (res) {
          setAsset(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getAsset();
  }, [])


  useEffect(() => {
    const token = Cookies.get('token');
    if (token)
      verify();
    else nevigate("/");
  }, [])





  // // Staking 1 contract reads
  // useContractRead({
  //   address: stakingAddress1,
  //   abi: StakingAbi1,
  //   functionName: "check",
  //   args: [address],
  //   onSuccess(data) {
  //     setAmount1(data[0].toString());
  //     setLockTime(Number(data[1]) * 1000);
  //   },
  //   watch: true,
  // });

  // useContractRead({
  //   address: stakingAddress1,
  //   abi: StakingAbi1,
  //   functionName: "getLokingDays",
  //   onSuccess(data) {
  //     setLockDays(data.toString());
  //   },
  //   watch: true,
  // });

  // useContractRead({
  //   address: stakingAddress1,
  //   abi: StakingAbi1,
  //   functionName: "LockingTime",
  //   onSuccess(data) {
  //     setLockingTime(Number(data) * 1000);
  //   },
  //   watch: true,
  // });

  // useContractRead({
  //   address: approvetoken,
  //   abi: approveAbi,
  //   functionName: "allowance",
  //   args: [address, stakingAddress1],
  //   onSuccess(data) {
  //     setAllowanceAmunt1(Number(data) * 1000);
  //   },
  //   watch: true,
  // });

  // // Staking 1 contract writes
  // const { write: approveusd, isLoading: val3 } = useContractWrite({
  //   address: approvetoken,
  //   abi: approveAbi,
  //   functionName: "approve",
  //   args: [
  //     stakingAddress1,
  //     "1157920892373161954235709850086879078532699846656405640394575840079131296399",
  //   ],
  //   onError(data) {
  //     toast.warn(`Approve Warning: ${data?.message || "Unknown error"}`);
  //   },
  //   onSuccess(data) {
  //     toast.success(`Approve successful: ${data.hash}`);
  //   },
  // });

  // const { write: lock, isLoading: val4 } = useContractWrite({
  //   address: stakingAddress1,
  //   abi: StakingAbi1,
  //   functionName: "lock",
  //   args: [entarlock],
  //   onError(data) {
  //     toast.warn(`Lock Warning: ${data?.message || "Unknown error"}`);
  //   },
  //   onSuccess(data) {
  //     toast.success(`Lock successful: ${data.hash}`);
  //   },
  // });

  // const { write: Unlock, isLoading: val5 } = useContractWrite({
  //   address: stakingAddress1,
  //   abi: StakingAbi1,
  //   functionName: "Unlock",
  //   onError(data) {
  //     toast.warn(`Unlock Warning: ${data?.message || "Unknown error"}`);
  //   },
  //   onSuccess(data) {
  //     toast.success(`Unlock successful: ${data.hash}`);
  //   },
  // });

  // // Staking 2 contract reads
  // useContractRead({
  //   address: stakingAddress2,
  //   abi: StakingAbi2,
  //   functionName: "check",
  //   args: [address],
  //   onSuccess(data) {
  //     setAmount2(data[0].toString());
  //     setLockTime2(Number(data[1]) * 1000);
  //   },
  //   watch: true,
  // });

  // useContractRead({
  //   address: stakingAddress2,
  //   abi: StakingAbi2,
  //   functionName: "getLokingDays",
  //   onSuccess(data) {
  //     setLockDays2(data.toString());
  //   },
  //   watch: true,
  // });

  // useContractRead({
  //   address: stakingAddress2,
  //   abi: StakingAbi2,
  //   functionName: "LockingTime",
  //   onSuccess(data) {
  //     setLockingTime2(Number(data) * 1000);
  //   },
  //   watch: true,
  // });

  // useContractRead({
  //   address: approvetoken,
  //   abi: approveAbi,
  //   functionName: "allowance",
  //   args: [address, stakingAddress2],
  //   onSuccess(data) {
  //     setAllowanceAmunt2(Number(data) * 1000);
  //   },
  //   watch: true,
  // });

  // // Staking 2 contract writes
  // const { write: approveusd2, isLoading: val2 } = useContractWrite({
  //   address: approvetoken,
  //   abi: approveAbi,
  //   functionName: "approve",
  //   args: [
  //     stakingAddress2,
  //     "1157920892373161954235709850086879078532699846656405640394575840079131296399",
  //   ],
  //   onError(data) {
  //     toast.warn(`Approve Warning: ${data?.message || "Unknown error"}`);
  //   },
  //   onSuccess(data) {
  //     toast.success(`Approve successful: ${data.hash}`);
  //   },
  // });

  // const { write: lock2, isLoading: val6 } = useContractWrite({
  //   address: stakingAddress2,
  //   abi: StakingAbi2,
  //   functionName: "lock",
  //   args: [entarlock2],
  //   onError(data) {
  //     toast.warn(`Lock Warning: ${data?.message || "Unknown error"}`);
  //   },
  //   onSuccess(data) {
  //     toast.success(`Lock successful: ${data.hash}`);
  //   },
  // });

  // const { write: Unlock2, isLoading: val7 } = useContractWrite({
  //   address: stakingAddress2,
  //   abi: StakingAbi2,
  //   functionName: "Unlock",
  //   onError(data) {
  //     toast.warn(`Unlock Warning: ${data?.message || "Unknown error"}`);
  //   },
  //   onSuccess(data) {
  //     toast.success(`Unlock successful: ${data.hash}`);
  //   },
  // });

  return (
    <StakingApp.Provider
      value={{
        amount1,
        lockTime,
        lockdays,
        lockingTime,
        entarlock,
        AllowanceAmunt1,
        setEntarlock,
     
        amount2,
        lockTime2,
        lockdays2,
        lockingTime2,
        entarlock2,
        AllowanceAmunt2,
        setEntarlock2,
        setIslogin,
        isLogin,
        setOpentab,
        openTab,
        setAuth,
        openAuth,
        Auth,
        user,
        asset,
        setWallets,
        wallets,
        setOpen1,
        open1,
        updateProfile
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
