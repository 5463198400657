import React from 'react'
import Card from "../Pages/Cards/index"

const CurrentPlayer = ({ gameResult, showResult, player, gameMoveStatus }) => {

    const cards = player['Hand'] || []


    return (
        <div>
            <div className={`relative flex  justify-center items-center `}>
                    <div className={`relative `}>
                        <div className={`w-full flex items-center justify-center gap-1`}>
                            {gameMoveStatus.betStatus === 'playing' && cards.map((card, index) => {
                                return <div className='fade-in-up'><Card key={index} card={card} className={'w-14 h-20 rounded-md'} /></div>
                            })}
                        </div>
                </div>

            </div>
        </div>
    )
}

export default CurrentPlayer