import React, { useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import sol from '../../../Image/sol.png';
import detect from '../../../Image/detection.png';
import QRCode from 'qrcode.react';
import { StakingApp } from '../../Authentication/Context/StakingApp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { DepositDetectionBot } from "../../index";

export default function DepositPage() {
  const { user } = useContext(StakingApp);
  const [selectedToken, setSelectedToken] = useState('SOL');
  const [copied, setCopied] = useState(false);
  const [openDetectionBot, setOpenDetectionBot] = useState(false);

  const getAddress = () => {
    if (selectedToken === 'SOL') {
      return user.solVirtualAddress;
    }
    return '';
  };

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 xl:px-10">
      {openDetectionBot && (
        <DepositDetectionBot Open={openDetectionBot} setOpen={setOpenDetectionBot} />
      )}

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {/* Deposit Details */}
          <div>
            <h2 className="text-white text-xl mb-6">Deposit Details for {selectedToken}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center justify-center">
              <QRCode
                className="border-4 border-gray-700"
                value={getAddress()}
                size={200}
              />
              <div className="flex flex-col gap-4 items-center">
                <p className="text-white text-base">
                  Minimum Deposit: 0.0005 {selectedToken}
                </p>
                <div className="h-16 bg-gray-800 w-72 px-3 rounded-md flex items-center justify-between">
                  <span className="text-white break-all">
                    {getAddress()}
                  </span>
                  <CopyToClipboard
                    text={getAddress()}
                    onCopy={() => setCopied(true)}
                  >
                    {copied ? (
                      <DoneIcon className="text-green-600 cursor-pointer" />
                    ) : (
                      <ContentCopyIcon className="text-white cursor-pointer" />
                    )}
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            <div className="bg-gray-800 p-6 mt-8 rounded-md">
              <b className="text-red-600">Notice:</b>
              <span className="text-white text-base">
                {' '}Do not deposit any currency other than {selectedToken}. After successfully depositing funds, ensure you activate the deposit detection bot to verify the transaction on-chain.
              </span>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-purple-800 hover:bg-purple-500 text-white flex py-4 px-8 rounded-lg mt-4 mx-auto block shadow-md"
              onClick={() => setOpenDetectionBot(true)}
            >
              <img
                src={detect}
                alt="detect"
                style={{ height: '40x', width: '30px' }}
                className="mr-2"
              />
              <span className="text-lg font-medium">Detect your deposit</span>
            </motion.button>
          </div>

          {/* Deposit History */}
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
            <h2 className="text-white text-xl mb-4">Deposit History</h2>
            <div className="overflow-auto max-h-64">
              <table className="min-w-full bg-gray-800 text-white rounded-lg overflow-hidden">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">Date</th>
                    <th className="px-4 py-2 text-left">Token</th>
                    <th className="px-4 py-2 text-left">Amount</th>
                    <th className="px-4 py-2 text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Add your dynamic data here */}
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  <tr>
                    <td className="border-t border-gray-700 px-4 py-2">2024-06-01</td>
                    <td className="border-t border-gray-700 px-4 py-2">SOL</td>
                    <td className="border-t border-gray-700 px-4 py-2">0.005</td>
                    <td className="border-t border-gray-700 px-4 py-2">Confirmed</td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
