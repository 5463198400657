import React, { useEffect, useState } from 'react'
import BetButtons from './BetButtons';
import { useSelector } from 'react-redux';
const SevenTwentySevenActionbuttons = ({ maxBet, pot, gameStatus, gameBetStatus }) => {


    const userData = useSelector(state => state.auth.userData)

    return (
        <div className=' flex flex-col items-center justify-center h-full w-full'>
            {(gameStatus == 'betting') && (gameBetStatus?.currentPlayer == userData?.id) && <BetButtons gameBetStatus={gameBetStatus} maxBet={maxBet} pot={pot} />}
        </div>
    )
}

export default SevenTwentySevenActionbuttons
