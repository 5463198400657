import React from 'react'
import ForwardIcon from '@mui/icons-material/Forward';
const ShowDownAction = ({ handleSelectedCardSubmit }) => {
  return (
    <div className='flex flex-col justify-center items-center mt-2'>
      <span className='font-bold text-white flex flex-row justify-center items-center'>
         <span className='text-xl '>Select any 5 cards to show down</span> <span className='ml-2 text-xs'> (Click on cards to select)</span>
      </span> 

      <button onClick={handleSelectedCardSubmit} className='bg-green-500 text-white font-bold px-4 py-0.5 rounded-lg mt-4 text-xs'>Show Down <ForwardIcon /></button>
    </div>
  )
}

export default ShowDownAction
