import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import { useSelector } from 'react-redux'
import Card from "../Common/Cards/index"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
const Player = ({ player, winner, totalPlayers, index, allLeftExceptWinner }) => {

  const getCardsClassName = (index) => {
    if (totalPlayers === 2) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
    }
    if (totalPlayers === 3) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute left-5 top-0 rotate-90'
      if (index === 2) return 'absolute right-5 rotate-90 top-0'
    }
    if (totalPlayers === 4) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute left-5 top-0 rotate-90'
      if (index === 2) return 'absolute -bottom-10 left-1/2 transform -translate-x-1/2'
      if (index === 3) return 'absolute right-5 rotate-90 top-0'
    }

    if (totalPlayers === 5) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute bottom-1/2 left-1 rotate-45 translate-y-1/4'
      if (index === 2) return 'absolute  top-1/2 left-1 -rotate-45 translate-y-1/4'
      if (index === 3) return 'absolute  top-1/2 right-1 rotate-45 translate-y-1/4'
      if (index === 4) return 'absolute bottom-1/2 right-1 -rotate-45 translate-y-1/4'
    }

    if (totalPlayers === 6) {
      if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
      if (index === 1) return 'absolute bottom-1/2 left-1 rotate-45 translate-y-1/4'
      if (index === 2) return 'absolute  top-1/2 left-1 -rotate-45 translate-y-1/4'
      if (index === 3) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
      if (index === 4) return 'absolute  top-1/2 right-1 rotate-45 translate-y-1/4'
      if (index === 5) return 'absolute bottom-1/2 right-1 -rotate-45 translate-y-1/4'
    }



  }

  const highLightWinner = (userId) => {
    if (!winner) return false
    if (winner && winner.length === 0) return false
    const winnersUserId = winner?.map(winner => winner.userId)
    return winnersUserId.includes(userId)
  }

  const Cards = () => {

    const allCards = player.cards || []
    if (allLeftExceptWinner) return null
    return allCards.map((card, index) => (
      <div className='flex flex-col items-center justify-center'>
        <Card className={`h-14 w-10 ${card.showDown ? "scale-125 border-2 border-purple-400 shadow-purple-200 shadow-2xl" : ""} rounded-sm`} key={index} card={card} index={index} textSize={'text-xs'} fontSize={12}/>
      </div>
    ))
  }

  const winnerCardHand = () => {
    if (allLeftExceptWinner) return false
    if (!highLightWinner(player.userId)) return false;
    const winnerPlayer = winner.find(winner => winner.userId === player.userId)
    return winnerPlayer?.hand ?  winnerPlayer?.hand : false
  }




  return (
    <div className='relative hi-lo-player flex items-center flex-col	'>

      {(!player.isFolded) && (!player.isLeft) && <div className={` flex items-center justify-center gap-1.5 ${getCardsClassName(index)}`}>
        <Cards />
      </div>}
      {player.isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-20 w-32  ${winner.userId == player.userId && ""}`} />}
      {player.isLeft && !player.isFolded && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile  h-16 w-16  p-1/2 ${winner.userId == player.userId && ""}`} />}
      {!player.isFolded && !player.isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${winner.userId == player.userId && ""}`} />}



      <div className={`relative flex flex-col items-center justify-center ${winner?.userId === player.userId ? "bg-green-400 text-black py-3 px-5" : 'bg-black text-white'} font-extrabold px-3 text-center border-2 rounded-md`}>
        <div className='name mb-1 text-xs'>{
          player.userName ? player.userName :
            formatAddress(player.address, 3)
        }</div>
        {highLightWinner(player.userId) && <span className='absolute -bottom-8 bg-purple-600 skew-x-12 py-0.5 px-2 w-36 text-center'>
          <EmojiEventsIcon className='mr-2'/>
          {winnerCardHand()}
          </span> } 
      </div>

    </div>
  )
}

export default Player
