import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Stars, OrbitControls } from '@react-three/drei';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiLinkExternal, BiCoinStack, BiUser } from 'react-icons/bi'; // Import icons
import Toast from '../../../CommonComponent/Toast';
import { useSelector } from 'react-redux';

const ReferralPage = () => {


  const { data } = useSelector((state) => state.userData);
  // Mock data
  const userEarnings = 120; // User's earnings
  const numberOfReferrals = 8; // Number of people referred

  const handleCopyReferralLink = () => {
    const referralLink = `${window.location.origin}/?ref=${data?.referralCode}`;
    navigator.clipboard.writeText(referralLink);
    toast.success(<Toast message={'Copied!'} status="success" />, {
      position: 'bottom-center',
      theme: 'dark',
      autoClose: 2000,
      transition: Slide,
    });
  };

  return (
    <div className="relative bg-background text-white min-h-full flex flex-col overflow-hidden">
      <div className="container mx-auto p-6 relative z-10">
        <h1 className="text-4xl font-bold text-[#FFD700] mb-4">Referral Earnings</h1>
        <p className="mb-4">
          Invite your friends to join our casino and earn rewards! Share your unique referral link and earn a percentage of their deposits.
        </p>
        <button
          onClick={handleCopyReferralLink}
          className="bg-[#FFD700] hover:bg-[#564d19] text-black font-bold py-2 px-4 rounded mb-4 flex items-center gap-2 z-10"
        >
          <BiLinkExternal /> {/* External link icon */}
          Copy Referral Link
        </button>
        <div className="bg-gray-800 p-4 rounded">
          <h2 className="text-2xl font-bold text-red-600 mb-2">Your Earnings</h2>
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex items-center mb-2">
              <BiCoinStack className="text-2xl text-yellow-400 mr-2" /> {/* Coin stack icon */}
              <p className="text-lg">Total Earnings: ${userEarnings}</p>
            </div>
            <div className="flex items-center">
              <BiUser className="text-2xl text-blue-400 mr-2" /> {/* User icon */}
              <p className="text-lg">Number of Referrals: {numberOfReferrals}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars className="text-black" />
          <OrbitControls />
        </Canvas>
      </div>
    </div>
  );
};

export default ReferralPage;
