import React from 'react'

import HeartCard from './HeartCard'
import SpadeCard from './SpadeCard'
import DiamondCard from './DiamondCard'
import ClubCard from './ClubCard'

const Card = ( { card, className, textSize, fontSize }) => {

    const Rank = card['Rank']
    const Suit = card['Suit']


  return (
    <div role='button' className={`${className ? className : "w-12 h-20 rounded-md "} bg-white  hover:scale-105'`}>
     {Suit == 'H' && <HeartCard Rank={Rank} fontSize={fontSize} textSize={textSize} />}
     {Suit == 'S' && <SpadeCard Rank={Rank} fontSize={fontSize} textSize={textSize} />}
     {Suit == 'D' && <DiamondCard Rank={Rank} fontSize={fontSize} textSize={textSize} />}
     {Suit == 'C' && <ClubCard Rank={Rank} fontSize={fontSize} textSize={textSize} />}

    </div>
  )
}

export default Card
