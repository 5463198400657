import React from 'react'
import coins from '../../../../../../../assets/img/coins.png'
import Players from '../../OtherPlayer'
import { useSelector } from 'react-redux'

const Dealer = ({ pot = 0 }) => {

    const solPrice = useSelector(state => state.auth.solPrice)
    const RenderUSD = ({ amount }) => {
        if (!solPrice) return null
        if (!amount) return null
        let newAmount = Number(amount)

        return <span className='text-sm text-white font-bold ms-2'>({(solPrice * newAmount).toFixed(2)} USD)</span>
    }

    return (
        <div className="flex flex-col justify-center items-center w-full text-xs top-40 ">
            <h1 className='text-white text-lg font-bold '>
                {pot.toFixed(2)} SOL
            </h1>
            <RenderUSD amount={pot} />
        </div>
    )
}

export default Dealer