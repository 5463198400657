// src/FutureProjectsPage.js
import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardHeader } from '@mui/material';

// Sample projects data
const projects = [
  {
    id: 1,
    name: 'NFT Collection Launch',
    description: 'Get ready for an exclusive collection of NFTs that will revolutionize digital ownership. Our upcoming launch will feature unique and rare NFTs that you won’t want to miss!. Our NFT holders will provide exclusive entry to our best games.',
    date: 'Coming Soon',
  },
  {
    id: 2,
    name: 'Token Launch',
    description: 'Be part of our new token launch that promises to bring innovative financial solutions to the blockchain. Our token will provide exciting opportunities for early adopters and gamers alike!',
    date: 'Coming Soon',
  },
  {
    id: 3,
    name: 'Metaverse Expansion',
    description: 'Step into the next level of virtual reality with our Metaverse project. Explore new worlds, meet new people, and engage in unique experiences that blur the lines between reality and the digital world!',
    date: 'Coming Soon',
  },
];

const FutureProjectsPage = () => {
  return (
    <div className="w-full h-full">
      <Container maxWidth="lg">
      <h2 className="text-4xl mt-8 font-bold text-white text-center mb-8 border-b-4 border-yellow-500 pb-2">
          Our Future Projects
        </h2>
        <Grid container spacing={4}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card className="relative flex flex-col h-full bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardHeader
                  title={<Typography variant="h5" className="text-yellow-400">{project.name}</Typography>}
                  subheader={<Typography variant="body2" className="text-gray-400">{project.date}</Typography>}
                  className="bg-gray-900 text-white"
                />
                <CardContent className="flex-1 p-6">
                  <Typography variant="body1" className="text-gray-600">
                    {project.description}
                  </Typography>
                </CardContent>
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 text-yellow-500 text-3xl font-bold opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <span className="transform rotate-[-15deg]">Coming Soon</span>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default FutureProjectsPage;
