import React from 'react'
import Cards from './Cards'
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import hotToast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../Config/axios';
const TableCards = ({ card, isNoCardPlayable }) => {

    const { gameAddress } = useParams();

    const handleGetDeckCard = async () => {
        try {
            const bodyData = { gameAddress };

            const response = await axiosInstance.post('/api/game/uno-card-game/get-deck-card', bodyData);

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000 });
            }

        } catch (error) {
            hotToast.error(error.message, { duration: 3000 });
        }
    }


    let tableCardColor = 'bg-white'
    if (card.color === 'red') {
        tableCardColor = 'bg-red-600'
    } else if (card.color === 'yellow') {
        tableCardColor = 'bg-yellow-600'
    } else if (card.color === 'green') {
        tableCardColor = 'bg-green-600'
    } else if (card.color === 'blue') {
        tableCardColor = 'bg-blue-600'
    } else if (card.value === 'wildDrawFour') {
        tableCardColor = 'bg-black'
    } else if (card.value === 'wild') {
        tableCardColor = 'bg-black'
    } else if (card.value === 'drawTwo') {
        tableCardColor = 'bg-black'
    } else if (card.value === 'reverse') {
        tableCardColor = 'bg-black'
    } else if (card.value === 'skip') {
        tableCardColor = 'bg-black'
    } 

    return (
        <div className='text-white flex justify-center items-center gap-2'>
            <div onClick={handleGetDeckCard}  id='deck_card' role='button' className={`relative active:scale-105 flex items-center justify-center deck_card bg-black border-2 ${isNoCardPlayable ? 'border-green-600 shadow-green-500 shadow-2xl' : 'border-gray-300'} rounded-lg p-2 h-32 w-20`}>
                {isNoCardPlayable && <span className='rotate-180 absolute -top-8 left-1/2 transform -translate-x-1/2 ' >
                    <PanToolAltIcon fontSize='24' className='animate-bounce text-white text-6xl' />
                </span>}

                <div className={`relative h-10 w-10 rounded-md rotate-45  `}>
                    <span className={`absolute card_one h-5 w-5 top-0 left-0 rounded-tl-md bg-green-500 `}></span>
                    <span className={`absolute card_one h-5 w-5 top-0 left-5 rounded-tr-md bg-red-500  `}></span>
                    <span className={`absolute card_one h-5 w-5 bottom-0 left-5 rounded-br-md bg-blue-500  `}></span>
                    <span className={`absolute card_one h-5 w-5 bottom-0 left-0 rounded-bl-md bg-yellow-500  `}></span>
                </div>
            </div>

            <div id='table_card' role='button '>
                <Cards card={card} className={'h-32 w-20 fade-in-up'} />
            </div>
            <div className='table_card_info ml-2 h-32 w-auto flex flex-col justify-evenly items-start'>
                <span role='button' className={`active:scale-105 h-8 w-8 ${tableCardColor} shadow-2xl shadow-white rotate-45`}></span>
                <div role='button' className={`active:scale-110 relative h-8 w-8 rounded-md rotate-45  `}>
                    <span className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45 font-extrabold text-4xl  text-black z-20`}>1</span>
                    <span className={`absolute card_one h-4 w-4 top-0 left-0  bg-green-500 `}></span>
                    <span className={`absolute card_one h-4 w-4 top-0 left-4  bg-red-500  `}></span>
                    <span className={`absolute card_one h-4 w-4 bottom-0 left-4  bg-blue-500  `}></span>
                    <span className={`absolute card_one h-4 w-4 bottom-0 left-0  bg-yellow-500  `}></span>
                </div>
            </div>
        </div>
    )
}

export default TableCards
