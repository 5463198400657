import React, { useContext, useLayoutEffect } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../../../Config/axios'
import socketIOClient from 'socket.io-client';
import JoinGame from './JoinGame';
import PlayGame from './PlayGame';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../CommonComponent/Toast';
import GameResult from '../../../../CommonComponent/GameResult';
import Loading from './Loading';
import { isEmpty } from '../../../../Config/function';
import { gsap } from 'gsap';
import coins from '../../../../assets/img/games/coins_yellow_transparent.png';
import Chat from '../../../ChatingComponent/chatingComponent';
import hotToast from 'react-hot-toast';

const ENDPOINT = process.env.REACT_APP_API_URL;


function Game() {

  // Routes params
  const { gameAddress } = useParams()
  const [socket, setSocket] = useState(null);

  const [isLoading, setIsLoading] = useState(false)
  const [gameInfo, setGameInfo] = useState(null)
  const [gameResult, setGameResult] = useState(null)
  const [userMoveInfo, setUserMoveInfo] = useState({})


  const fetchGameInformation = async (data) => {
    try {
      setIsLoading(true)
      const bodyData = { gameAddress, showResult: data?.onResult }

      const response = await axiosInstance.post('/api/game/info', bodyData)

      const { data: responseData } = response
      if (!responseData.status) {
      } else {
        setGameInfo(responseData.data)
        if (responseData.data.userMove) setUserMoveInfo(responseData.data.userMove)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
     
      hotToast.error(error.message, { duration: 2000 })
    }
  }


  const animateFlowToPot = (sourceId, targetId, isCard) => {
    const playerElement = document.getElementById(sourceId);
    const targetElement = document.getElementById(targetId);

    if (!playerElement || !targetElement) return;

    const playerRect = playerElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    // Create a card element (or image)
    let card = null
    card = document.createElement('img');
    card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle';
    card.src = coins;
    card.style.top = `${playerRect.top + window.scrollY}px`;
    card.style.left = `${playerRect.left + window.scrollX}px`;

    document.body.appendChild(card);

    const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
    const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

    gsap.to(card, {
      duration: 1.0,
      x: x,
      y: y,
      onComplete: () => {
        card.remove();
      }
    });
  };

  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('opponentJoined', (data) => {
      let { address  } = data
      if (address) {
        address = address.slice(0, 6) + '...' + address.slice(-4)
      } else {
        address = 'New User'
      }
      // toast.success(<ToastContent status='success' message={`${address} has joined the game`} />, {
      //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      // })
      hotToast.success(`${address} has joined the game`, { duration: 2000 })
      fetchGameInformation()
    });

    newSocket.on('moveChange', (data) => {
      fetchGameInformation()
    });

    newSocket.on('gameStarted', (data) => {
      fetchGameInformation()
    });

    newSocket.on('roomJoined', (data) => {
      const { roomId } = data
      if (roomId === gameAddress) fetchGameInformation()
    });

    newSocket.on('playerLeft', (data) => {
      const { address } = data
      const userData = JSON.parse(localStorage.getItem('userData'))
      fetchGameInformation()
      if (address == userData.address) return;

      // toast.error(<ToastContent status='error' message={`Opponent has left the game`} />, {
      //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      // })
      hotToast.error(`Opponent has left the game`, { duration: 2000 })
    });

    newSocket.on('result', (data) => {
      const userData = JSON.parse(localStorage.getItem('userData'))

      const { address } = userData
      const { winner } = data


      let result = ''
      if (winner) {
        result = (winner === address) ? 'win' : 'lose'
      } else {
        result = 'draw'
      }
      

      setTimeout(() => {
        setGameResult(result)
        setTimeout(() => {
          let curentPlayerId = 'rps_current_player';
          let opponentPlayerId = 'rps_opponent_player';
          if (result === 'win') {
            animateFlowToPot(opponentPlayerId, curentPlayerId, false);
          } else if (result === 'lose') {
            animateFlowToPot(curentPlayerId, opponentPlayerId, false);
          } else {
            // do nothing
          }
          setGameResult(null)
          updateUserMoveInfo()
        }, 3000);

      }, 5000);

    });

    return () => {
      newSocket.off('opponentJoined');
      newSocket.off('moveChange');
      newSocket.off('gameStarted');
      newSocket.off('roomJoined');
      newSocket.off('playerLeft');
      newSocket.off('result');
    }
  }, []);

  const joinGame = async () => {
    try {
      const amount = 500;
      const assetId = 1
      const bodyData = { gameAddress, amount, assetId }

      const response = await axiosInstance.post('/api/game/join', bodyData)

      const { data: responseData } = response
      if (!responseData.status) {
        // toast.error(<ToastContent status='error' message={responseData.message} />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // })
        hotToast.error(responseData.message, { duration: 2000 })
      } else {
        const data = { slug: 'rock-paper-scissors', gameAddress }
        localStorage.setItem('currentJoinedGame', JSON.stringify(data))
        socket.emit('joinedGame', { roomId: gameAddress });
      }
    } catch (error) {
      // toast.error(<ToastContent status='error' message={error.message} />, {
      //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      // })
      hotToast.error(error.message, { duration: 2000 })
    }
  }

  const updateUserMoveInfo = async () => {
    try {
      const response = await axiosInstance.post('/api/game/new-move', { gameAddress })
      const { data: responseData } = response
      if (!responseData.status) {
        // toast.error(<ToastContent status='error' message={responseData.message} />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // })
        hotToast.error(responseData.message, { duration: 2000 })
      } else {
        fetchGameInformation()
      }

    } catch (error) {
      // toast.error(<ToastContent status='error' message={error.message} />, {
      //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      // })
      hotToast.error(error.message, { duration: 2000 })
    }
  }

  useEffect(() => {
    fetchGameInformation()
  }, [socket])

  const leaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/leave', { gameAddress })

      const { data: responseData } = response
      if (!responseData.status) {
        // toast.error(<ToastContent status='error' message={responseData.message} />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // })
        hotToast.error(responseData.message, { duration: 2000 })
      } else {
        if (socket) socket.emit('playerLeft', { roomId: gameAddress })
      }
    } catch (error) {
      // console.log(error.message)
      hotToast.error(error.message, { duration: 2000 })
    }
  }

  useLayoutEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave the game?';
      event.returnValue = message;
      return message;
    };

    const handleUnload = () => {
      leaveGame();
    };

    window.addEventListener('beforeunload', leaveGame);
    window.addEventListener('unload', leaveGame);

    return () => {
      window.removeEventListener('beforeunload', leaveGame);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  if (isLoading && isEmpty(gameInfo)) return <Loading />
  const userData = JSON.parse(localStorage.getItem('userData'))

  return (
    <div className='h-full w-[100%]'>
      <div class="grid grid-cols-7 gap-2 rounded relative h-full">
         <Chat userId={userData?.id}  roomId={gameAddress}  channelName={'RPS'} />
        {!gameInfo?.userMove?.status && <div class="col-span-7  w-[80%] mx-auto flex items-center  justify-center  rounded-xl h-full"><JoinGame fetchGameInformation={fetchGameInformation} joinGame={joinGame} gameInfo={gameInfo} /></div>}
        {gameInfo?.userMove?.status && <div class="col-span-7 px-3 h-full flex items-center  justify-center  rounded-md"><PlayGame  result={gameResult} leaveGame={leaveGame} gameResult={gameResult} fetchGameInformation={fetchGameInformation} userMoveInfo={userMoveInfo} gameInfo={gameInfo} socket={socket} /></div>}
      </div>
      {gameResult && <GameResult result={gameResult} gameInfo={gameInfo} />}
    </div >
  )
}

export default Game
